import {
	GETTING_SHARED_TEMPLATE,
	GET_SHARED_TEMPLATE_SUCCESS,
	GET_SHARED_TEMPMLATE_FAILED,
	SEARCHING_SHARED_TEMPLATE,
	SEARCH_SHARED_TEMPLATE_FAILED,
	SEARCH_SHARED_TEMPLATE_SUCCESS,
} from '../../constants/ActionTypes'
const INIT_STATE = {
	data: [],
	loadingTemplate: false,
	status: '',
	message: '',
	isSearchTemplate: false,
	param: '',
}

export const SharedTemplateReducer = (
	state = INIT_STATE,
	{ type, payload }
) => {
	switch (type) {
		case GETTING_SHARED_TEMPLATE:
			return {
				...state,
				loadingTemplate: true,
			}
		case GET_SHARED_TEMPLATE_SUCCESS:
			return {
				...state,
				data: payload,
				loadingTemplate: false,
				isSearchTemplate: false,
			}
		case GET_SHARED_TEMPMLATE_FAILED:
			return {
				...state,
				loadingTemplate: false,
				message: String(payload),
			}

		case SEARCHING_SHARED_TEMPLATE:
			return {
				...state,
				loadingTemplate: true,
			}
		case SEARCH_SHARED_TEMPLATE_SUCCESS:
			return {
				...state,
				loadingTemplate: false,
				data: payload.result,
				isSearchTemplate: true,
				param: payload.data.param,
			}
		case SEARCH_SHARED_TEMPLATE_FAILED:
			return {
				...state,
				loadingTemplate: false,
				message: String(payload),
				isSearchTemplate: true,
			}
		default:
			return state
	}
}
