import { axiosWrapper } from '../axiosClient'
import HCL from 'js-hcl-parser'

const url = '/deployment'
export const useDeploymentService = async (dataLaunch) => {
	// dynamic parameter for AWS, AZURE
	let dynamicParameter = {}
	for (let key in dataLaunch) {
		if (key.startsWith('_paraKeys.')) {
			let parsedKey = key.replace('_paraKeys.', '')
			dynamicParameter[parsedKey] = dataLaunch[key]
		}
	}

	// dynamic parameter for Terraform
	let parameters = {}
	for (let key in dataLaunch) {
		if (key.startsWith('_paraKeys.')) {
			let parsedKey = key.replace('_paraKeys.', '')
			parameters[parsedKey] = dataLaunch[key]
		}
	}

	let terraformObj = { variable: parameters }
	Object.entries(parameters).forEach(([key, value]) => {
		if (typeof value === 'string') {
			parameters[key] = { type: typeof value, default: `\u2328${value}\u2328` }
		} else {
			parameters[key] = { type: typeof value, default: value }
		}
	})

	// convert parameter json to HCL
	const jsonInput = JSON.stringify(terraformObj)
	const HclOutput = HCL.stringify(jsonInput)

	// replace quote of HCL file
	const withoutQuotes = HclOutput.replaceAll('"', '')

	//replace special character to quote for value of HCL file
	const HclOutputReplaceQuote = withoutQuotes.replaceAll('\u2328', '"')

	console.log(dataLaunch)
	const axiosClient = axiosWrapper.getAxiosClient()
	var deploymentData = JSON.stringify({
		deploymentName: dataLaunch.deploymentName,
		region: dataLaunch.region,
		templateId: dataLaunch.idService,
		cloudUUID: dataLaunch.cloudAccount,
		description: dataLaunch.description,
		parameters:
			dataLaunch.iacType === 'GCP' ? HclOutputReplaceQuote : dynamicParameter,
		templateUrl:
			dataLaunch.iacType === 'GCP'
				? dataLaunch.templateGCP
				: dataLaunch.templateUrl,
		paramUrl: dataLaunch.paramUrl,
		tags: dataLaunch.tagsObj,
		timezone: 'Asia/Ho_Chi_Minh',
		repeat: null,
		notify: null,
	})

	let response = {
		result: 0, // 0: success, 1: failed
		responseData: null,
	}
	try {
		response.responseData = await axiosClient.post(
			`${url}/launch`,
			deploymentData
		)
	} catch (err) {
		response.result = 1
		if (err && err.response) {
			response.responseData = err.response
		}
	}

	return response
}

export const useGetAllListDeployment = async ({
	page,
	pageSize,
	businessUnitId,
}) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	try {
		var response = await axiosClient.get(
			`${url}/search-deployments?page=${page}&limit=${pageSize}&businessUnits=${businessUnitId}`
		)
	} catch (err) {
		if (err && err.response) {
			response = err.response
		}
	}

	return response
}

export const useRefreshListDeployment = async ({
	param,
	page = 1,
	businessUnitId,
}) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient
		.get(
			`${url}/refresh-deployments?page=${page}&limit=10&${param}&businessUnits=${businessUnitId}`
		)
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

export const useGetDeploymentListServicebyFilter = async ({
	param,
	page,
	pageSize,
	businessUnitId,
}) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient
		.get(
			`${url}/search-deployments?page=${page}&limit=${pageSize}&${param}&businessUnits=${businessUnitId}`
		)
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

//get detail
export const useGetDetailDeploymentListById = async (id) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient.get(`${url}/${id}`).catch(function (error) {
		if (error) {
			return Promise.reject(error)
		}
	})
	return res
}

//clean deployment

export const useCleanDeploymentById = async (id) => {
	const axiosClient = axiosWrapper.getAxiosClient()

	let response = {
		result: 0, // 0: success, 1: failed
		responseData: null,
	}
	try {
		response.responseData = await axiosClient.post(`${url}/clean/${id}`)
	} catch (err) {
		response.result = 1
		if (err && err.response) {
			response.responseData = err.response
		}
	}

	return response
}
