import { all } from 'redux-saga/effects'
import CloudAccountSaga from './CloudAccountSaga'
import DeploymentServiceSaga from './DeploymentServiceSaga'
import DetailServiceSaga from './DetailServiceSaga'
import ServiceSaga from './ServiceSaga'
import SharedTemplateSaga from './SharedTemplateSaga'

export default function* rootSaga() {
	yield all([
		ServiceSaga(),
		DetailServiceSaga(),
		CloudAccountSaga(),
		DeploymentServiceSaga(),
		SharedTemplateSaga(),
	])
}
