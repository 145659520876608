import {
	GETTING_LIST_DEPLOYMENT,
	GET_LIST_DEPLOYMENT_FAIL,
	GET_LIST_DEPLOYMENT_SUCCESS,
	REFRESHING_LIST_DEPLOYMENT,
	REFRESH_CLEAN_STATUS,
	REFRESH_LIST_DEPLOYMENT_FAILED,
	REFRESH_LIST_DEPLOYMENT_SUCCESS,
	SEARCHING_LIST_DEPLOYMENT,
	SEARCH_LIST_DEPLOYMENT_FAILED,
	SEARCH_LIST_DEPLOYMENT_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	data: [],
	loading: false,
	status: '',
	message: '',
	isSearch: false,
	param: '',
	isRefresh: false,
}

export const deploymentListReducer = (
	state = INIT_STATE,
	{ type, payload }
) => {
	switch (type) {
		case GETTING_LIST_DEPLOYMENT:
			return {
				...state,
				loading: true,
			}
		case GET_LIST_DEPLOYMENT_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
				isSearch: false,
			}
		case GET_LIST_DEPLOYMENT_FAIL:
			return {
				...state,
				loading: false,
				message: String(payload),
				isSearch: false,
			}
		case SEARCHING_LIST_DEPLOYMENT:
			return {
				...state,
				loading: true,
			}
		case SEARCH_LIST_DEPLOYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload.result,
				isSearch: true,
				isRefresh: true,
				param: payload.data.param,
			}
		case SEARCH_LIST_DEPLOYMENT_FAILED:
			return {
				...state,
				loading: false,
				message: String(payload),
				isSearch: true,
				isRefresh: true,
			}

		case REFRESHING_LIST_DEPLOYMENT:
			return {
				...state,
				loading: true,
			}
		case REFRESH_LIST_DEPLOYMENT_SUCCESS:
			return {
				...state,
				data: payload.result,
				param: payload.data.param,
				loading: false,
				isRefresh: true,
			}
		case REFRESH_LIST_DEPLOYMENT_FAILED:
			return {
				...state,
				message: String(payload),
				loading: false,
				isRefresh: true,
			}
		case REFRESH_CLEAN_STATUS:
			return {
				...state,
				isRefresh: false,
			}
		default:
			return state
	}
}
