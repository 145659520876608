import {
	SET_CLOUD_ACCOUNTS,
	SELECT_CLOUD_ACCOUNT,
	SELECT_PROVIDER,
	SELECT_ALL_CLOUD_ACCOUNT,
	DESELECT_ALL_CLOUD_ACCOUNT,
	GET_CLOUD_ACCOUNT_FAILED,
	GET_CLOUD_ACCOUNT_SUCCESS,
} from '@constants/ActionTypes'

export const setAccounts = (accounts) => {
	return {
		type: SET_CLOUD_ACCOUNTS,
		payload: accounts,
	}
}

export const selectAccountByVendor = (accounts, provider) => {
	return {
		type: SELECT_CLOUD_ACCOUNT,
		payload: { accounts, provider },
	}
}

export const selectAccount = (accounts) => {
	return {
		type: SELECT_ALL_CLOUD_ACCOUNT,
		payload: accounts,
	}
}

export const deselectAllAccount = () => {
	return {
		type: DESELECT_ALL_CLOUD_ACCOUNT,
	}
}

export const selectProvider = (provider) => {
	return {
		type: SELECT_PROVIDER,
		payload: provider,
	}
}

export const GetAccountSuccess = (data) => {
	return {
		type: GET_CLOUD_ACCOUNT_SUCCESS,
		payload: data,
	}
}

export const GetAccountFailed = (data) => {
	return {
		type: GET_CLOUD_ACCOUNT_FAILED,
		payload: data,
	}
}
