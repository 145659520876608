import { call, put, takeLatest } from 'redux-saga/effects'
import {
	useGetAllService,
	useCreateService,
	useGetServicebyFilter,
	useUpdateService,
	useDeleteServiceById,
} from '../../../api/hooks/ServiceCatalogHook'
import { ServiceCatalogActionType } from '../../constants/ActionTypes'
import { openNotification } from '../../helpers'
import {
	CreateServiceFailed,
	CreateServiceSuccess,
	CreattingService,
	DeleteServiceFailed,
	DeleteServiceSuccess,
	DeletingService,
	GetServiceFailed,
	GetServiceSuccess,
	GettingService,
	SearchingService,
	SearchServiceFailed,
	SearchServiceSuccess,
	UpdateServiceFailed,
	UpdateServiceSuccess,
	UpdatingService,
} from '../actions/ServiceCatalog'

function* fetchServices({ data }) {
	try {
		yield put(GettingService())

		const result = yield call(useGetAllService, data)
		if (result.length === 0) {
			yield put(GetServiceFailed())
		} else yield put(GetServiceSuccess(result))
	} catch (error) {
		yield put(GetServiceFailed(error))
		yield put(GetServiceSuccess([]))
	}
}

function* createService({ data }) {
	try {
		yield put(CreattingService())

		const result = yield call(useCreateService, data)
		if (
			result.templateDataProcessing.status >= 400 &&
			result.templateDataProcessing.status <= 499
		) {
			yield put(CreateServiceFailed(result))
			//openNotification('error', 'Create Service Catalog failed!')
		} else if (
			result.templateDataProcessing.status >= 200 &&
			result.templateDataProcessing.status <= 399
		) {
			yield put(CreateServiceSuccess(result?.templateDataProcessing.status))
			openNotification('success', 'Create Service Catalog successfully!')
		}
	} catch (error) {
		if (error.response.data.imgUrl) {
			var message = error.response.data.imgUrl.message
			yield put(CreateServiceFailed(message))
			openNotification('error', message)
		}
		yield put(CreateServiceFailed(error.response.data.message))
		//openNotification('error', error.response.data.message)
	}
}

function* searchService({ data }) {
	try {
		yield put(SearchingService())

		const result = yield call(useGetServicebyFilter, data)
		if (result.docs) {
			yield put(SearchServiceSuccess({ data, result }))
		}
	} catch (error) {
		yield put(SearchServiceFailed(error))
	}
}

function* updateService({ dataSubmit: data }) {
	try {
		yield put(UpdatingService())

		const result = yield call(useUpdateService, data)
		if (
			result.templateDataProcessing.status >= 200 &&
			result.templateDataProcessing.status <= 399
		) {
			yield put(UpdateServiceSuccess(result))
			openNotification('success', 'Update Service Catalog successfully!')
		} else if (
			result?.templateDataProcessing.status >= 400 &&
			result?.templateDataProcessing.status <= 499
		) {
			yield put(UpdateServiceFailed(result.templateDataProcessing.message))
		}
	} catch (error) {
		yield put(UpdateServiceFailed(error.response.data.message))
	}
}

function* deleteService({ idService }) {
	try {
		yield put(DeletingService())

		const result = yield call(useDeleteServiceById, idService)
		if (result.deletedCount > 0) {
			yield put(DeleteServiceSuccess(result.deletedCount))
			openNotification('success', 'Delete Service Catalog successfully!')
		} else {
			yield put(DeleteServiceFailed(result.deletedCount))
			openNotification('error', 'Delete Service Catalog failed!')
		}
	} catch (error) {
		yield put(DeleteServiceFailed(error.response.data.message))
	}
}

function* ServiceSaga() {
	yield takeLatest(ServiceCatalogActionType.FETCH_SERVICE, fetchServices)
	yield takeLatest(ServiceCatalogActionType.CREATE_SERVICE, createService)
	yield takeLatest(ServiceCatalogActionType.SEARCH_SERVICE, searchService)
	yield takeLatest(ServiceCatalogActionType.UPDATE_SERVICE, updateService)
	yield takeLatest(ServiceCatalogActionType.DELETE_SERVICE, deleteService)
}

export default ServiceSaga
