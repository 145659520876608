import React from 'react'
import { PUBLIC_URL, LANDING_URL } from '@common/config'
import { cleanURL } from '@util'
import asyncComponentLoader from '@util/asyncComponent'
import SafeRoute from '@util/SafeRoute'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { SSO_ACCESS_TOKEN, SSO_REFRESH_TOKEN } from '@constants/sso/type'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import viVN from 'antd/lib/locale/vi_VN'
import jaJP from 'antd/lib/locale/ja_JP'

const App = ({ match, translate }) => {
	const asyncComponent = (path, prefix, title) => {
		return asyncComponentLoader(() => import(`${path}`), {
			prefixRoute: cleanURL(`${matchUrl}/${prefix}`),
			title: title,
			translate: translate,
		})
	}

	const matchUrl = cleanURL(`${match.url}${PUBLIC_URL}`)

	//SSO
	const dataSSO = [
		{ id: 'ifr_identity', url: process.env.REACT_APP_IDENTITY },
		{ id: 'ifr_security', url: process.env.REACT_APP_SECURITY_ASSURANCE },
		{ id: 'ifr_expense', url: process.env.REACT_APP_EXPENSE },
		{ id: 'ifr_portal', url: process.env.REACT_APP_PORTAL },
	]

	const token = [
		{
			key: SSO_ACCESS_TOKEN,
			value: localStorage.getItem(SSO_ACCESS_TOKEN),
		},
		{
			key: SSO_REFRESH_TOKEN,
			value: localStorage.getItem(SSO_REFRESH_TOKEN),
		},
	]

	function ifOnload(id, url) {
		if (
			localStorage.getItem(SSO_ACCESS_TOKEN) &&
			localStorage.getItem(SSO_REFRESH_TOKEN)
		) {
			document.getElementById(id).contentWindow.postMessage(token, `${url}/`)
		}
	}
	let lang = enUS
	switch (translate.language) {
		case 'en_US':
			lang = enUS
			break
		case 'vi_VN':
			lang = viVN
			break
		case 'ja_JP':
			lang = jaJP
			break
		default:
	}

	return (
		<div className="gx-page-layout portals">
			<ConfigProvider locale={lang}>
				<Switch>
					<Route path={`${matchUrl}`} exact={true}>
						<Redirect to={cleanURL(`${matchUrl}/service-catalog`)} />
					</Route>{' '}
					<SafeRoute
						path={cleanURL(`${matchUrl}/virtual-machine/details/:vmid`)}
						component={asyncComponent(
							'./VirtualMachine/Details',
							'project',
							'SIDEBAR.ASSET.VIRTUALMACHINE.DETAIL'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/virtual-machine`)}
						component={asyncComponent(
							'./VirtualMachine',
							'project',
							'SIDEBAR.ASSET.VIRTUALMACHINE'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/scheduler`)}
						component={asyncComponent(
							'./Scheduler',
							'project',
							'SIDEBAR.ASSET.SCHEDULER'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/database`)}
						component={asyncComponent(
							'./Database',
							'project',
							'SIDEBAR.ASSET.DATABASE'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/service-catalog`)}
						component={asyncComponent(
							'./ServiceCatalog',
							'project',
							'SIDEBAR.ASSET.SERVICECATALOG'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/shared-template`)}
						component={asyncComponent(
							'./SharedTemplate',
							'project',
							'SIDEBAR.ASSET.SHAREDTEMPLATE'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/deployment`)}
						component={asyncComponent(
							'./Deployment',
							'project',
							'SIDEBAR.ASSET.DEPLOYMENT'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/launch-deployment/:template_id`)}
						component={asyncComponent(
							'./LaunchDeployment',
							'project',
							'SIDEBAR.ASSET.LAUNCHDEPLOYMENT'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/deployment-detail/:deployment_id`)}
						component={asyncComponent(
							'./DetailListDeployment',
							'project',
							'SIDEBAR.ASSET.DETAILLISTDEPLOYMENT'
						)}
					/>
					<SafeRoute
						path={cleanURL(`${matchUrl}/notification`)}
						component={asyncComponent(
							'./Notification',
							'notification',
							'NOTIFICATION'
						)}
					/>
					<SafeRoute
						path={'*'}
						component={() => (window.location.href = `${LANDING_URL}/404`)}
					/>{' '}
				</Switch>
			</ConfigProvider>
			{dataSSO &&
				dataSSO.map((item) => {
					return (
						<iframe
							style={{ display: 'none' }}
							title={item.id}
							key={item.id}
							id={item.id}
							onLoad={() => ifOnload(item.id, item.url)}
							sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
							src={`${item.url}/getlocalstorage.html`}
						/>
					)
				})}
		</div>
	)
}

export default withTranslation('translations')(App)
