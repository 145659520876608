import { AuthHttp } from '@common/services'
import { from, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { V1_IDENTITY_API_URL, V1_API_URL } from '@common/config'
import { openNotification } from '@common/helpers'

export function getUserProfile() {
	let url = 'users/profile'
	return from(
		AuthHttp.get(`${V1_IDENTITY_API_URL}/${url}`).catch((e) => {
			openNotification('error', "Get user's profile error")
		})
	)
}

export function getPermission() {
	let url = 'users/permissions'
	return from(
		AuthHttp.get(`${V1_IDENTITY_API_URL}/${url}`).catch((e) => {
			openNotification('error', "Get user's permission error")
		})
	)
}

export function getCloudAccountByCostCenter(businessIds) {
	let url = `cloud-accounts?apps=CLOUD_OPS`
	return from(AuthHttp.get(`${V1_IDENTITY_API_URL}/${url}`)).pipe(
		map((res) => {
			return {
				data: {
					content: res.data.map((item) => {
						item.csp === 'AWS'
							? Object.assign(item, { accountId: item.cspCredential.accountId })
							: item.csp === 'AZURE'
							? Object.assign(item, {
									accountId: item.cspCredential.subscriptionId,
							  })
							: item.csp === 'VMW'
							? Object.assign(item, {
									accountId: item.cspCredential.userName,
									id: item.accountUuid,
							  })
							: Object.assign(item, { accountId: item.cspCredential.projectId })
						return item
					}),
				},
			}
		})
	)
}

export function getCloudAccountAWS(
	accountIds,
	Service,
	LookbackPeriodInDays,
	PaymentOption,
	TermInYears,
	OfferingClass,
	InstanceType,
	Region
) {
	let url = `ri/aws/?Service=${Service}${parseCloudAccountIdsParams(
		accountIds
	)}&LookbackPeriodInDays=${LookbackPeriodInDays}&PaymentOption=${PaymentOption}&TermInYears=${TermInYears}`
	if (OfferingClass) {
		url += `&OfferingClass=${OfferingClass}`
	}
	if (InstanceType) {
		url += `&InstanceType=${InstanceType}`
	}
	if (Region) {
		url += `&Region=${Region}`
	}
	return from(
		AuthHttp.get(`${V1_API_URL}/${url}`).catch((e) => {
			openNotification('error', 'Get ri error')
		})
	)
}

export function getCloudAccountAZURE(
	accountIds,
	Scope,
	LookbackPeriodInDays,
	TermInYears,
	Service,
	InstanceType,
	Region
) {
	let url = `ri/azure/?Scope=${Scope}${parseCloudAccountIdsParams(
		accountIds
	)}&LookbackPeriodInDays=${LookbackPeriodInDays}&TermInYears=${TermInYears}&Service=${Service}`
	if (InstanceType) {
		url += `&InstanceType=${InstanceType}`
	}
	if (Region) {
		url += `&Region=${Region}`
	}
	return from(
		AuthHttp.get(`${V1_API_URL}/${url}`).catch((e) => {
			openNotification('error', 'Get ri error')
		})
	)
}

const parseCloudAccountIdsParams = (accountIds) => {
	if (accountIds.length === 0) {
		return '&accountIds='
	}

	let params = ''
	Array.from(accountIds).forEach((e) => (params += `&accountIds=${e}`))
	return params
}

/**
 * GET /api/cost-centers
 * Get cost center list
 */
export function getCostCenters(companyId) {
	const url = `/cost-centers?companyId=${companyId}`
	return from(AuthHttp.get(`${V1_API_URL}/${url}`)).pipe(
		map((res) => {
			if (res.data && !res.data.length) {
				openNotification('error', 'Cost center list is empty!')
				return []
			}
			return res.data.map(({ id, name }) => ({ id, name }))
		}),
		catchError((e) => {
			openNotification(
				'error',
				'Occurred error when load cost center list from server!'
			)
			return of([])
		})
	)
}

export function getRegions(UUID) {
	return from(
		AuthHttp.get(`${V1_IDENTITY_API_URL}/cloud-accounts/regions/${UUID}`)
	)
}

/**
 * Update /api/public/business-units/setDefaultBu?buId={buId}&userId={userId}
 *
 * @param userId User Id (Required)
 * @param buId Business Unit Id (Required)
 */
export function setDefaultBU(params) {
	return from(
		AuthHttp.patch(`${V1_IDENTITY_API_URL}/business-units/setDefaultBu`, params)
	).pipe(
		catchError((e) => {
			const messages = e.response.data.messages
			openNotification(
				'error',
				messages
					? messages[0].message
					: 'Occurred error when set default bu from server!'
			)
			return of({})
		})
	)
}

/**
 * PUT /api/public/user/profile.
 * Update User Profiles
 */
export function updateUserProfile(body) {
	const url = `${V1_IDENTITY_API_URL}/users/info`
	return from(AuthHttp.put(`${V1_API_URL}/${url}`, body)).pipe(
		map((res) => res.data),
		catchError((e) => {
			openNotification(
				'error',
				e.response.data.messages
					? e.response.data.messages[0].message
					: 'Occurred error when load user information!'
			)
			return of({})
		})
	)
}

/**
 * PUT /change-password
 *
 * @param body
 * {
    "currentPassword":"",
    "newPassword":"",
    "confirmNewPassword":""
  }
*/
export function changePassword(body) {
	const url = `${V1_IDENTITY_API_URL}/users/change-password`
	return from(AuthHttp.put(`${V1_API_URL}/${url}`, body)).pipe(
		catchError((e) => {
			openNotification(
				'error',
				e.response.data.messages
					? e.response.data.messages[0].message
					: 'Occurred error when change password!'
			)
			return of({})
		})
	)
}

export function getUserInfo() {
	const url = `${V1_IDENTITY_API_URL}/users/info`
	return from(AuthHttp.get(url)).pipe(
		map((res) => res.data),
		catchError((e) => {
			openNotification(
				'error',
				e.response.data.messages
					? e.response.data.messages[0].message
					: 'Occurred error when load user information!'
			)
			return of({})
		})
	)
}

export function getMachineTypes(cloudUUID, zone) {
	return from(
		AuthHttp.get(
			`${V1_IDENTITY_API_URL}/cloud-accounts/machineType/${cloudUUID}?zone=${zone}`
		)
	)
}
