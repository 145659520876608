import {
	CLEANNING_DEPLOYMENT,
	CLEAN_DEPLOYMENT_FAILED,
	CLEAN_DEPLOYMENT_SUCCESS,
	RESET_STATUS_CLEAN,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	status: 0,
	cleanData: [],
	loading: false,
	isCleanSuccess: undefined,
}
export const cleanDeploymentServiceReducer = (
	state = INIT_STATE,
	{ type, payload }
) => {
	switch (type) {
		case CLEANNING_DEPLOYMENT:
			return {
				...state,
				loading: true,
			}
		case CLEAN_DEPLOYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				cleanData: payload,
				isCleanSuccess: true,
			}
		case CLEAN_DEPLOYMENT_FAILED:
			return {
				...state,
				loading: false,
				cleanData: payload,
				isCleanSuccess: false,
			}
		case RESET_STATUS_CLEAN:
			return {
				...state,
				isCleanSuccess: undefined,
			}
		default:
			return state
	}
}
