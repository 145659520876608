import moment from 'moment'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import vi from 'javascript-time-ago/locale/vi'
import ja from 'javascript-time-ago/locale/ja'
import { cryptoRandom } from '../helpers'
import Resizer from 'react-image-file-resizer'
export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}
export function cleanURL(url) {
	if (url == null) {
		return url
	}

	return url.replace(/\/\//gm, '/')
}

export function roundNumber(number, fixedPoint = 2) {
	if (Number.isNaN(Number.parseFloat(number + ''))) {
		return 0
	}
	return Math.round(number * fixedPoint * 10) / (fixedPoint * 10)
}

export function formatDate(date) {
	return moment(new Date(date)).locale('en').format('DD-MM-YYYY')
}
export function formatDateTimeSlash(date) {
	return moment(new Date(date)).locale('en').format('DD/MM/YYYY hh:mm A')
}
export function formatDateWithSecond(date) {
	return moment(new Date(date)).locale('en').local().format('hh:mm A')
}

export function getRandomColor(index) {
	const COLORS = [
		'#f79a56',
		'#43a047',
		'#4c87cd',
		'#ea0530',
		'#ff6347',
		'#f0ad4e',
		'#c532a4',
		'#27d7cc',
	]
	if (index < COLORS.length) {
		return COLORS[index]
	}

	let st = '#'
	for (var i = 0; i < 6; i++) {
		st += '0123456789ABCDEF'[Math.floor(cryptoRandom() * 16)]
	}
	return st
}

export function ParseTime(str) {
	if (!/^(\d){14}$/.test(str)) return 'invalid date'
	var y = str.substr(0, 4),
		m = str.substr(4, 2) - 1,
		d = str.substr(6, 2),
		h = str.substr(8, 2),
		minute = str.substr(10, 2),
		s = str.substr(12, 2)
	return new Date(y, m, d, h, minute, s)
}
export function convertUTCDateToLocalDate(date) {
	var parseDate = new Date(date)
	var newDate = parseDate.getTime() - parseDate.getTimezoneOffset() * 60 * 1000

	return newDate
}
export function formatDateAgo(t, date) {
	TimeAgo.addLocale(en)
	let timeAgo = new TimeAgo('en-US')
	if ('ja_JP' === t.language) {
		TimeAgo.addLocale(ja)
		timeAgo = new TimeAgo('ja-JP')
	}
	if ('vi_VN' === t.language) {
		TimeAgo.addLocale(vi)
		timeAgo = new TimeAgo('vi-VN')
	}

	return timeAgo.format(
		new Date(moment.utc(convertUTCDateToLocalDate(date)).local())
	)
}

export const resizeFile = (file, width, height) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			(width = 150),
			(height = 150),
			'jpeg',
			100,
			0,
			(uri) => {
				resolve(uri)
			},
			'file',
			(width = 150),
			(height = 150)
		)
	})

export const UNITS = {
	SECONDS: 'Seconds',
	MICROSECONDS: 'Microseconds',
	MILLISECONDS: 'Milliseconds',
	BYTES: 'Bytes',
	KILOBYTES: 'Kilobytes',
	MEGABYTES: 'Megabytes',
	GIGABYTES: 'Gigabytes',
	TERABYTES: 'Terabytes',
	BITS: 'Bits',
	KILOBITS: 'Kilobits',
	MEGABITS: 'Megabits',
	GIGABITS: 'Gigabits',
	TERABITS: 'Terabits',
	PERCENT: '%',
	COUNT: 'Units',
	BYTES_SECOND: 'Bytes/Second',
	KILOBYTES_SECOND: 'Kilobytes/Second',
	MEGABYTES_SECOND: 'Megabytes/Second',
	GIGABYTES_SECOND: 'Gigabytes/Second',
	TERABYTES_SECOND: 'Terabytes/Second',
	BITS_SECOND: 'Bits/Second',
	KILOBITS_SECOND: 'Kilobits/Second',
	MEGABITS_SECOND: 'Megabits/Second',
	GIGABITS_SECOND: 'Gigabits/Second',
	TERABITS_SECOND: 'Terabits/Second',
	COUNT_SECOND: 'Count/Second',
}

export const METRIC_TYPES = [
	'SAMPLE_COUNT',
	'AVERAGE',
	'SUM',
	'MINIMUM',
	'MAXIMUM',
]

export const TIME_SELECTION = [
	{ text: '1 second', value: 1 },
	{ text: '5 seconds', value: 5 },
	{ text: '10 seconds', value: 10 },
	{ text: '30 seconds', value: 30 },
	{ text: '1 minute', value: 60 },
	{ text: '5 minutes', value: 300 },
	{ text: '15 minutes', value: 900 },
	{ text: '30 minutes', value: 1800 },
	{ text: '1 hour', value: 3600 },
	{ text: '6 hours', value: 21600 },
	{ text: '1 day', value: 86400 },
	{ text: '7 days', value: 604800 },
	{ text: '30 days', value: 2592000 },
]

export const METRIC_CATEGORY_TYPE = [
	'CPUUtilization',
	'NetworkIn',
	'NetworkOut',
	'NetworkPacketsIn',
	'NetworkPacketsOut',
	'DiskReadOps',
	'DiskWriteOps',
	'DiskReadBytes',
	'DiskWriteBytes',
]

export function formatNumber(num) {
	return num.toLocaleString(
		'en-US', // leave undefined to use the visitor's browser
		// locale or a string like 'en-US' to override it.
		{ minimumFractionDigits: 0 }
	)
}

export const DataFormater = (number) => {
	if (number > 1000000000) {
		return (number / 1000000000).toFixed(1) + 'B'
	} else if (number > 1000000) {
		return (number / 1000000).toFixed(1) + 'M'
	} else if (number > 1000) {
		return (number / 1000).toFixed(1) + 'K'
	} else {
		return parseFloat(number).toFixed(1)
	}
}
