import {
	GETTING_DETAIL_LIST_DEPLOYMENT,
	GET_DETAIL_LIST_DEPLOYMENT_SUCCESS,
	GET_DETAIL_LIST_DEPLOYMENT_FAILED,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	data: [],
	status: undefined,
	loading: false,
	message: '',
}
export const detailListDeploymentServiceReducer = (
	state = INIT_STATE,
	{ type, payload }
) => {
	switch (type) {
		case GETTING_DETAIL_LIST_DEPLOYMENT:
			return {
				...state,
				data: [],
				loading: true,
			}
		case GET_DETAIL_LIST_DEPLOYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			}
		case GET_DETAIL_LIST_DEPLOYMENT_FAILED:
			return {
				...state,
				loading: false,
				message: payload,
			}
		default:
			return state
	}
}
