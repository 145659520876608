import { call, put, takeLatest } from 'redux-saga/effects'
import { useGetDetailServiceById } from '../../../api/hooks/ServiceCatalogHook'
import { ServiceCatalogActionType } from '../../constants/ActionTypes'
import {
	GetDetailServiceFailed,
	GetDetailServiceSuccess,
	GettingDetailService,
} from '../actions/ServiceCatalog'

function* fetchDetailService(data) {
	try {
		yield put(GettingDetailService())

		const result = yield call(useGetDetailServiceById, data.template_id)
		if (result) {
			yield put(GetDetailServiceSuccess(result))
		} else yield put(GetDetailServiceFailed())
	} catch (error) {
		yield put(GetDetailServiceFailed(error.message))
	}
}

function* DetailServiceSaga() {
	yield takeLatest(
		ServiceCatalogActionType.FETCH_DETAIL_SERVICE,
		fetchDetailService
	)
}

export default DetailServiceSaga
