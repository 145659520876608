import {
	RESET_STATUS,
	UPDATE_SERVICE_FAILED,
	UPDATE_SERVICE_SUCCESS,
	UPDATING_SERVICE,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	data: [],
	loading: false,
	status: '',
	message: '',
	isUpdateSuccess: undefined,
}

export const updateServiceReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case UPDATING_SERVICE:
			return {
				...state,
				loading: true,
			}
		case UPDATE_SERVICE_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				isUpdateSuccess: true,
			}
		case UPDATE_SERVICE_FAILED:
			return {
				...state,
				loading: false,
				message: String(payload),
				isUpdateSuccess: false,
			}
		case RESET_STATUS:
			return {
				...state,
				status: '',
				isUpdateSuccess: undefined,
				message: '',
			}
		default:
			return state
	}
}
