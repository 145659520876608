import { Layout } from 'antd'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import App from '@pages'
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import i18n from 'i18next'

const { Content } = Layout

const MainApp = () => {
	const match = useRouteMatch()

	return (
		<Layout className="gx-app-layout">
			<Sidebar translate={i18n} />
			<Layout>
				<Topbar translate={i18n} />
				<Content
					className={'gx-layout-content gx-container-wrap '}
					id="body-content"
				>
					<App match={match} translate={i18n} />
				</Content>
			</Layout>
		</Layout>
	)
}
export default MainApp
