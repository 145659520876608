import { AuthHttp } from '@common/services'
import { openNotification } from '@common/helpers'

export const getCloudAccount = async () => {
	let url = 'cloud-accounts/me'
	const res = await AuthHttp.get(`/${url}`).catch(function (error) {
		if (error) {
			return openNotification('error', 'Get Cloud Account error')
		}
	})
	return res
}
