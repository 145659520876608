import React from 'react'
import Icon from '@ant-design/icons'

// import { ReactComponent as DashboardSVG } from "@assets/images/sidebar/Monitor.svg";
import { ReactComponent as MenuSVG } from '@assets/images/sidebar/Menu.svg'
import { ReactComponent as VirtualMachineSVG } from '@assets/images/sidebar/Virtual-machine.svg'
import { ReactComponent as ServiceCatalogSVG } from '@assets/images/sidebar/Service Catalog.svg'
import { ReactComponent as CalendarSGV } from '@assets/images/sidebar/calendar.svg'
import { ReactComponent as DeploymentSVG } from '@assets/images/sidebar/Deployment.svg'
import { ReactComponent as SharedSVG } from '@assets/images/sidebar/SharedTemplate.svg'

import identityIcon from '@assets/images/icons/identity-logo.svg'
import expenseIcon from '@assets/images/icons/expense-logo.svg'
import safeIcon from '@assets/images/icons/safe-logo.svg'

export const SIDEBAR_MENU = [
	{
		id: 'Asset',
		text: 'SIDEBAR.ASSET',
		icon: <Icon component={() => <MenuSVG />} />,
		children: [
			{
				id: 'Service_Catalog',
				text: 'SIDEBAR.ASSET.SERVICECATALOG',
				url: '/service-catalog',
				icon: <Icon component={() => <ServiceCatalogSVG />} />,
				key: 'ops_sc',
			},
			{
				id: 'Shared_Template',
				text: 'SIDEBAR.ASSET.SHAREDTEMPLATE',
				url: '/shared-template',
				icon: <Icon component={() => <SharedSVG />} />,
				key: 'ops_template',
			},
			{
				id: 'Deployment',
				text: 'SIDEBAR.ASSET.DEPLOYMENT',
				url: '/deployment',
				icon: <Icon component={() => <DeploymentSVG />} />,
				key: 'ops_deployment',
			},
			{
				id: 'Virtual_Machine',
				text: 'SIDEBAR.ASSET.VIRTUALMACHINE',
				url: '/virtual-machine',
				icon: <Icon component={() => <VirtualMachineSVG />} />,
				key: 'ops_vm',
			},
			{
				id: 'Instance_Scheduler',
				text: 'SIDEBAR.ASSET.SCHEDULER',
				url: '/scheduler',
				icon: <Icon component={() => <CalendarSGV />} />,
				key: 'ops_schedules',
			},
			/*
	  {
		id: "Database",
		text: "SIDEBAR.ASSET.DATABASE",
		url: "/database",
		icon: <Icon component={() => <DatabaseSVG />} />,
	  },
	  */

			//   {
			//     id: "Project",
			//     text: "SIDEBAR.ASSET.PROJECT",
			//     url: "/project",
			//     icon: <Icon component={() => <ProjectSVG />} />,
			//   },
			//   {
			//     id: "Resource",
			//     text: "SIDEBAR.ASSET.RESOURCE",
			//     url: "/resource",
			//     icon: <Icon component={() => <ResourceSVG />} />,
			//   },
		],
	},
	//   {
	//     id: "Configure",
	//     text: "SIDEBAR.CONFIGURE",
	//     icon: <Icon component={() => <MenuSVG />} />,
	//     children: [
	//       {
	//         id: "Project",
	//         text: "SIDEBAR.CONFIGURE.PROJECT",
	//         url: "/configure/project",
	//         icon: <Icon component={() => <ProjectSVG />} />,
	//       },
	//       {
	//         id: "Resource_Type",
	//         text: "SIDEBAR.CONFIGURE.RESOURCE_TYPE",
	//         url: "/configure/resource-type",
	//         icon: <Icon component={() => <ResourceTypeSVG />} />,
	//       },
	//       /*
	//       {
	//         id: 'Integration',
	//         text: 'SIDEBAR.CONFIGURE.INTERGRATION',
	//         url: '/configure/intergration',
	//         icon: <Icon component={() => <IntegrationSVG />} />,
	//       }
	// 			*/
	//     ],
	//   },
]

export const MENU_NAVIGATION = [
	{
		id: 'expense',
		text: 'Cloud Expense',
		icon: expenseIcon,
		url: process.env.REACT_APP_EXPENSE,
	},
	{
		id: 'security',
		text: 'Cloud Safe',
		icon: safeIcon,
		url: process.env.REACT_APP_SECURITY_ASSURANCE,
	},
	{
		id: 'identity',
		text: 'Cloud Identity',
		icon: identityIcon,
		url: process.env.REACT_APP_IDENTITY,
	},
]
