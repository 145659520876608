import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from './Settings'
import Auth from './Auth'
import User from './User'
import Notes from './Notes'
import Common from './Common'
import Accounts from './Accounts'
import UserProfile from './UserProfile'
import { ServiceReducer } from './ServiceCatalog'
import { createServiceReducer } from './CreateService'
import { detailServiceReducer } from './DetailService'
import { updateServiceReducer } from './UpdateService'
import { deleteServiceReducer } from './DeleteService'
import { deploymentReducer } from './DeploymentService'
import { templateURLReducer } from './GetTemplateURLService'
import { deploymentListReducer } from './GetDeploymentService'
import { detailListDeploymentServiceReducer } from './DetailListDeployment'
import { cleanDeploymentServiceReducer } from './CleanDeployment'
import { SharedTemplateReducer } from './SharedTemplateService'
import { cloneTemplateReducer } from './CloneTemplate'

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		settings: Settings,
		user: User,
		auth: Auth,
		accounts: Accounts,
		notes: Notes,
		common: Common,
		userProfile: UserProfile,
		serviceCatalog: ServiceReducer,
		createService: createServiceReducer,
		detailService: detailServiceReducer,
		updateService: updateServiceReducer,
		deleteService: deleteServiceReducer,
		deploymentService: deploymentReducer,
		fetchTemplateURLService: templateURLReducer,
		fetchListDeployment: deploymentListReducer,
		detailListDeployment: detailListDeploymentServiceReducer,
		cleanDeployment: cleanDeploymentServiceReducer,
		sharedTemplateService: SharedTemplateReducer,
		cloneTemplateService: cloneTemplateReducer,
	})

export default createRootReducer
