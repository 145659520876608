import {
	SELECT_USER_COMPANY,
	SELECT_USER_COST_CENTER,
	SELECT_USER_BUSINESS_UNIT,
} from '@constants/ActionTypes'

const INIT_STATE = {
	company: {},
	costCenter: {},
	businessUnits: {},
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case SELECT_USER_COMPANY:
			return {
				...state,
				company: action.payload,
			}
		case SELECT_USER_COST_CENTER:
			return {
				...state,
				costCenter: action.payload,
			}
		case SELECT_USER_BUSINESS_UNIT:
			return {
				...state,
				businessUnits: action.payload,
			}
		default:
			return state
	}
}
