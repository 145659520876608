import { call, put, takeLatest } from 'redux-saga/effects'
import {
	useCleanDeploymentById,
	useDeploymentService,
	useGetAllListDeployment,
	useGetDeploymentListServicebyFilter,
	useGetDetailDeploymentListById,
	useRefreshListDeployment,
} from '../../../api/hooks/DeploymentHook'
import {
	ServiceDeploymentActionType,
	ServiceDeploymentListActionType,
} from '../../constants/ActionTypes'
import {
	CleanDeploymentFailed,
	CleanDeploymentSuccess,
	CleanningDeployment,
	CreateServiceDeploymentFailed,
	CreateServiceDeploymentSuccess,
	CreattingServiceDeployment,
	GetDetailDeploymentListServiceFailed,
	GetDetailDeploymentListServiceSuccess,
	GetListDeploymentServiceFailed,
	GetListDeploymentServiceSuccess,
	GettingDetailDeploymentListService,
	GettingListDeploymentService,
	RefreshDeploymentListServiceFailed,
	RefreshDeploymentListServiceSuccess,
	RefreshingDeploymentListService,
	SearchDeploymentListServiceFailed,
	SearchDeploymentListServiceSuccess,
	SearchingDeploymentListService,
} from '../actions/Deployment'

// launch template
function* deploymentService({ dataLaunch }) {
	yield put(CreattingServiceDeployment())
	const response = yield call(useDeploymentService, dataLaunch)
	if (response.result === 0) {
		//success
		if (response.responseData) {
			// process response data
			return yield put(CreateServiceDeploymentSuccess(response.responseData))
		}
		return yield put(CreateServiceDeploymentSuccess(response?.status))
	} else {
		//failed
		if (response.responseData) {
			// process response data
			return yield put(
				CreateServiceDeploymentFailed(
					response.responseData.data.message
						? response.responseData.data.message
						: response.responseData.data.detail
				)
			)
		}
		return yield put(
			CreateServiceDeploymentFailed(
				response.responseData.data.message
					? response.responseData.data.message
					: response.responseData.data.detail
			)
		)
	}
}

// fetch list deployment
function* fetchListDeploymentServices({ data }) {
	try {
		yield put(GettingListDeploymentService())

		const result = yield call(useGetAllListDeployment, data)
		if (result?.length === 0) {
			yield put(GetListDeploymentServiceFailed())
		} else yield put(GetListDeploymentServiceSuccess(result))
	} catch (error) {
		yield put(GetListDeploymentServiceFailed(error))
	}
}

// refresh
function* refreshListDeploymentService({ data }) {
	try {
		yield put(RefreshingDeploymentListService())

		const result = yield call(useRefreshListDeployment, data)
		if (result.docs) {
			yield put(RefreshDeploymentListServiceSuccess({ data, result }))
		}
	} catch (error) {
		yield put(RefreshDeploymentListServiceFailed(error))
	}
}

// search
function* searchListDeploymentService({ data }) {
	try {
		yield put(SearchingDeploymentListService())

		const result = yield call(useGetDeploymentListServicebyFilter, data)
		if (result.docs) {
			yield put(SearchDeploymentListServiceSuccess({ data, result }))
		}
	} catch (error) {
		yield put(SearchDeploymentListServiceFailed(error))
	}
}

// fetch detail list deployment
function* fetchDetailListDeploymentService(data) {
	try {
		yield put(GettingDetailDeploymentListService())
		const result = yield call(
			useGetDetailDeploymentListById,
			data.deployment_id
		)
		if (result) {
			yield put(GetDetailDeploymentListServiceSuccess(result))
		} else yield put(GetDetailDeploymentListServiceFailed())
	} catch (error) {
		yield put(GetDetailDeploymentListServiceFailed(error.message))
	}
}

// clean deployment

function* cleanDeploymentService({ id }) {
	yield put(CleanningDeployment())
	const response = yield call(useCleanDeploymentById, id)
	if (response.result === 0) {
		//success
		if (response.responseData) {
			//process response data
			return yield put(CleanDeploymentSuccess(response.responseData))
		}
		return yield put(CleanDeploymentSuccess(response?.status))
	} else {
		//failed
		if (response.responseData) {
			//process response data
			return yield put(CleanDeploymentFailed(response.responseData))
		}
		return yield put(CleanDeploymentFailed(response))
	}
}

function* DeploymentServiceSaga() {
	yield takeLatest(
		ServiceDeploymentActionType.DEPLOYMENT_SERVICE,
		deploymentService
	)
	yield takeLatest(
		ServiceDeploymentListActionType.FETCH_LIST_DEPLOYMENT,
		fetchListDeploymentServices
	)
	yield takeLatest(
		ServiceDeploymentListActionType.SEARCH_LIST_DEPLOYMENT,
		searchListDeploymentService
	)
	yield takeLatest(
		ServiceDeploymentListActionType.FETCH_DETAIL_LIST_DEPLOYMENT,
		fetchDetailListDeploymentService
	)
	yield takeLatest(
		ServiceDeploymentListActionType.CLEAN_DEPLOYMENT,
		cleanDeploymentService
	)
	yield takeLatest(
		ServiceDeploymentListActionType.REFRESH_DEPLOYMENT,
		refreshListDeploymentService
	)
}

export default DeploymentServiceSaga
