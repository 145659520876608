export const STRING = {
	EMPTY: '',
	SEPARATE: ' - ',
	PERCENTAGE: '%',
	SPACE: ' ',
	MINUS: '-',
	EQUAL: '=',
}
export const RES_COL = {
	lg: 24,
	md: 24,
	sm: 24,
	xs: 24,
}
