import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import portalLayoutMiddleware from '../middlewares/PortalLayoutMiddleware'
import createRootReducer from '../reducers'

import rootSaga from '../sagas/rootSaga'

const isDev =
	process.env.NODE_ENV === 'development' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const composeEnhancers = isDev
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	: compose

const createBrowserHistory = require('history').createBrowserHistory
const sagaMiddleware = createSagaMiddleware()

export const history = createBrowserHistory()

const routeMiddleware = routerMiddleware(history)
const middlewares = [
	thunk,
	portalLayoutMiddleware,
	routeMiddleware,
	sagaMiddleware,
]

export default function configureStore(preloadedState) {
	const store = createStore(
		createRootReducer(history), // root reducer with router state
		preloadedState,
		composeEnhancers(applyMiddleware(...middlewares))
	)

	sagaMiddleware.run(rootSaga)

	return store
}
