import axios from 'axios'
import { getAccessToken } from '@common/helpers'

import { PUBLIC_API_URL } from '../config'

// create an axios instance
const http = axios.create({
	baseURL: PUBLIC_API_URL,
	headers: {
		'content-type': 'application/json',
		Authorization: getAccessToken(),
	},
	timeout: 5000, // request timeout
})

// response interceptor
http.interceptors.response.use(
	(response) => {
		const res = response.data

		return res
	},
	(error) => {
		return Promise.reject(error)
	}
)

export default http
