import {
	GETTING_SERVICE,
	GET_SERVICE_FAILED,
	GET_SERVICE_SUCCESS,
	CREATE_SERVICE_SUCCESS,
	CREATTING_SERVICE,
	CREATE_SERVICE_FAILED,
	GETTING_DETAIL_SERVICE,
	GET_DETAIL_SERVICE_FAILED,
	GET_DETAIL_SERVICE_SUCCESS,
	DELETE_SERVICE_FAILED,
	DELETE_SERVICE_SUCCESS,
	DELETING_SERVICE,
	RESET_STATUS,
	SEARCHING_SERVICE,
	SEARCH_SERVICE_FAILED,
	SEARCH_SERVICE_SUCCESS,
	UPDATING_SERVICE,
	UPDATE_SERVICE_FAILED,
	UPDATE_SERVICE_SUCCESS,
} from '@constants/ActionTypes'

export const GettingService = () => {
	return {
		type: GETTING_SERVICE,
	}
}

export const GetServiceSuccess = (data) => {
	return {
		type: GET_SERVICE_SUCCESS,
		payload: data,
	}
}

export const GetServiceFailed = (data) => {
	return {
		type: GET_SERVICE_FAILED,
		payload: data,
	}
}

export const GettingDetailService = () => {
	return {
		type: GETTING_DETAIL_SERVICE,
	}
}

export const GetDetailServiceSuccess = (data) => {
	return {
		type: GET_DETAIL_SERVICE_SUCCESS,
		payload: data,
	}
}

export const GetDetailServiceFailed = (data) => {
	return {
		type: GET_DETAIL_SERVICE_FAILED,
		payload: data,
	}
}

export const CreattingService = () => {
	return {
		type: CREATTING_SERVICE,
	}
}
export const CreateServiceSuccess = (data) => {
	return {
		type: CREATE_SERVICE_SUCCESS,
		payload: data,
	}
}

export const CreateServiceFailed = (data) => {
	return {
		type: CREATE_SERVICE_FAILED,
		payload: data,
	}
}

export const SearchingService = () => {
	return {
		type: SEARCHING_SERVICE,
	}
}

export const SearchServiceSuccess = (data) => {
	return {
		type: SEARCH_SERVICE_SUCCESS,
		payload: data,
	}
}

export const SearchServiceFailed = (data) => {
	return {
		type: SEARCH_SERVICE_FAILED,
		payload: data,
	}
}

export const UpdatingService = () => {
	return {
		type: UPDATING_SERVICE,
	}
}

export const UpdateServiceSuccess = (data) => {
	return {
		type: UPDATE_SERVICE_SUCCESS,
		payload: data,
	}
}

export const UpdateServiceFailed = (data) => {
	return {
		type: UPDATE_SERVICE_FAILED,
		payload: data,
	}
}

export const ResetStatus = () => {
	return {
		type: RESET_STATUS,
	}
}

export const DeletingService = () => {
	return {
		type: DELETING_SERVICE,
	}
}

export const DeleteServiceSuccess = (data) => {
	return {
		type: DELETE_SERVICE_SUCCESS,
		payload: data,
	}
}

export const DeleteServiceFailed = (data) => {
	return {
		type: DELETE_SERVICE_FAILED,
		payload: data,
	}
}
