import { call, put, takeLatest } from 'redux-saga/effects'
import { getCloudAccount } from '../../../api/hooks/AccountHook'
import { CloudAccountActionType } from '../../constants/ActionTypes'
import { GetAccountFailed, GetAccountSuccess } from '../actions/Accounts'

function* fetchCloudAccount() {
	try {
		const result = yield call(getCloudAccount)
		if (result) {
			yield put(GetAccountSuccess(result.data))
		}
	} catch (error) {
		yield put(GetAccountFailed(error))
	}
}

function* CloudAccountSaga() {
	yield takeLatest(CloudAccountActionType.FETCH_ACCOUNT, fetchCloudAccount)
}

export default CloudAccountSaga
