export const TIME_MODE = {
	YEARLY: 'yearly',
	MONTHLY: 'monthly',
	DAILY: 'daily',
}

export const MODE = {
	DAILY: 'daily',
	MONTHLY: 'monthly',
	YEARLY: 'year',
}

export const TIME_FORMAT = {
	DATE: 'YYYY-MM-DD',
	MONTH: 'YYYY-MM',
	YEAR: 'YYYY',
}

export const TIME_ZONE = [
	{
		display: '(UTC-12:00) International Date Line West',
		id: 'Etc/GMT+12',
	},
	{
		display: '(UTC-11:00) Coordinated Universal Time-11',
		id: 'Etc/GMT+11',
	},
	{
		display: '(UTC-10:00) Hawaii',
		id: 'Pacific/Honolulu',
	},
	{
		display: '(UTC-09:00) Alaska',
		id: 'America/Anchorage',
	},
	{
		display: '(UTC-08:00) Baja California',
		id: 'America/Santa_Isabel',
	},
	{
		display: '(UTC-08:00) Pacific Time (US and Canada)',
		id: 'America/Los_Angeles',
	},
	{
		display: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
		id: 'America/Chihuahua',
	},
	{
		display: '(UTC-07:00) Arizona',
		id: 'America/Phoenix',
	},
	{
		display: '(UTC-07:00) Mountain Time (US and Canada)',
		id: 'America/Denver',
	},
	{
		display: '(UTC-06:00) Central America',
		id: 'America/Guatemala',
	},
	{
		display: '(UTC-06:00) Central Time (US and Canada)',
		id: 'America/Chicago',
	},
	{
		display: '(UTC-06:00) Saskatchewan',
		id: 'America/Regina',
	},
	{
		display: '(UTC-06:00) Guadalajara, Mexico City, Monterey',
		id: 'America/Mexico_City',
	},
	{ display: '(UTC-05:00) Bogota, Lima, Quito', id: 'America/Bogota' },
	{ display: '(UTC-05:00) Indiana (East)', id: 'America/Indiana/Indianapolis' },
	{
		display: '(UTC-05:00) Eastern Time (US and Canada)',
		id: 'America/New_York',
	},
	{
		display: '(UTC-04:30) Caracas',
		id: 'America/Caracas',
	},
	{
		display: '(UTC-04:00) Atlantic Time (Canada)',
		id: 'America/Halifax',
	},
	{
		display: '(UTC-04:00) Asuncion',
		id: 'America/Asuncion',
	},
	{
		display: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
		id: 'America/La_Paz',
	},
	{
		display: '(UTC-04:00) Cuiaba',
		id: 'America/Cuiaba',
	},
	{
		display: '(UTC-04:00) Santiago',
		id: 'America/Santiago',
	},
	{
		display: '(UTC-03:30) Newfoundland',
		id: 'America/St_Johns',
	},
	{
		display: '(UTC-03:00) Brasilia',
		id: 'America/Sao_Paulo',
	},
	{
		display: '(UTC-03:00) Greenland',
		id: 'America/Godthab',
	},
	{
		display: '(UTC-03:00) Cayenne, Fortaleza',
		id: 'America/Cayenne',
	},
	{
		display: '(UTC-03:00) Buenos Aires',
		id: 'America/Argentina/Buenos_Aires',
	},
	{
		display: '(UTC-03:00) Montevideo',
		id: 'America/Montevideo',
	},
	{
		display: '(UTC-02:00) Coordinated Universal Time-2',
		id: 'Etc/GMT+2',
	},
	{
		display: '(UTC-01:00) Cape Verde',
		id: 'Atlantic/Cape_Verde',
	},
	{
		display: '(UTC-01:00) Azores',
		id: 'Atlantic/Azores',
	},
	{
		display: '(UTC+00:00) Casablanca',
		id: 'Africa/Casablanca',
	},
	{
		display: '(UTC+00:00) Monrovia, Reykjavik',
		id: 'Atlantic/Reykjavik',
	},
	{
		display: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
		id: 'Europe/London',
	},
	{
		display: '(UTC+00:00) Coordinated Universal Time',
		id: 'Etc/GMT',
	},
	{
		display: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		id: 'Europe/Berlin',
	},
	{
		display: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
		id: 'Europe/Paris',
	},
	{
		display: '(UTC+01:00) West Central Africa',
		id: 'Africa/Lagos',
	},
	{
		display: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		id: 'Europe/Budapest',
	},
	{
		display: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
		id: 'Europe/Warsaw',
	},
	{
		display: '(UTC+01:00) Windhoek',
		id: 'Africa/Windhoek',
	},
	{
		display: '(UTC+02:00) Athens, Bucharest, Istanbul',
		id: 'Europe/Istanbul',
	},
	{
		display: '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
		id: 'Europe/Kiev',
	},
	{
		display: '(UTC+02:00) Cairo',
		id: 'Africa/Cairo',
	},
	{
		display: '(UTC+02:00) Damascus',
		id: 'Asia/Damascus',
	},
	{
		display: '(UTC+02:00) Amman',
		id: 'Asia/Amman',
	},
	{
		display: '(UTC+02:00) Harare, Pretoria',
		id: 'Africa/Johannesburg',
	},
	{
		display: '(UTC+02:00) Jerusalem',
		id: 'Asia/Jerusalem',
	},
	{
		display: '(UTC+02:00) Beirut',
		id: 'Asia/Beirut',
	},
	{
		display: '(UTC+03:00) Baghdad',
		id: 'Asia/Baghdad',
	},
	{
		display: '(UTC+03:00) Minsk',
		id: 'Europe/Minsk',
	},
	{
		display: '(UTC+03:00) Kuwait, Riyadh',
		id: 'Asia/Riyadh',
	},
	{
		display: '(UTC+03:00) Nairobi',
		id: 'Africa/Nairobi',
	},
	{
		display: '(UTC+03:30) Tehran',
		id: 'Asia/Tehran',
	},
	{
		display: '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
		id: 'Europe/Moscow',
	},
	{
		display: '(UTC+04:00) Tbilisi',
		id: 'Asia/Tbilisi',
	},
	{
		display: '(UTC+04:00) Yerevan',
		id: 'Asia/Yerevan',
	},
	{
		display: '(UTC+04:00) Abu Dhabi, Muscat',
		id: 'Asia/Dubai',
	},
	{
		display: '(UTC+04:00) Baku',
		id: 'Asia/Baku',
	},
	{
		display: '(UTC+04:00) Port Louis',
		id: 'Indian/Mauritius',
	},
	{
		display: '(UTC+04:30) Kabul',
		id: 'Asia/Kabul',
	},
	{
		display: '(UTC+05:00) Tashkent',
		id: 'Asia/Tashkent',
	},
	{
		display: '(UTC+05:00) Islamabad, Karachi',
		id: 'Asia/Karachi',
	},
	{
		display: '(UTC+05:30) Sri Jayewardenepura Kotte',
		id: 'Asia/Colombo',
	},
	{
		display: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
		id: 'Asia/Kolkata',
	},
	{
		display: '(UTC+05:45) Kathmandu',
		id: 'Asia/Kathmandu',
	},
	{
		display: '(UTC+06:00) Astana',
		id: 'Asia/Almaty',
	},
	{
		display: '(UTC+06:00) Dhaka',
		id: 'Asia/Dhaka',
	},
	{
		display: '(UTC+06:00) Yekaterinburg',
		id: 'Asia/Yekaterinburg',
	},
	{
		display: '(UTC+06:30) Yangon',
		id: 'Asia/Yangon',
	},
	{
		display: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
		id: 'Asia/Bangkok',
	},
	{
		display: '(UTC+07:00) Novosibirsk',
		id: 'Asia/Novosibirsk',
	},
	{
		display: '(UTC+08:00) Krasnoyarsk',
		id: 'Asia/Krasnoyarsk',
	},
	{
		display: '(UTC+08:00) Ulaanbaatar',
		id: 'Asia/Ulaanbaatar',
	},
	{
		display: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
		id: 'Asia/Shanghai',
	},
	{
		display: '(UTC+08:00) Perth',
		id: 'Australia/Perth',
	},
	{
		display: '(UTC+08:00) Kuala Lumpur, Singapore',
		id: 'Asia/Singapore',
	},
	{
		display: '(UTC+08:00) Taipei',
		id: 'Asia/Taipei',
	},
	{
		display: '(UTC+09:00) Irkutsk',
		id: 'Asia/Irkutsk',
	},
	{
		display: '(UTC+09:00) Seoul',
		id: 'Asia/Seoul',
	},
	{
		display: '(UTC+09:00) Osaka, Sapporo, Tokyo',
		id: 'Asia/Tokyo',
	},
	{
		display: '(UTC+09:30) Darwin',
		id: 'Australia/Darwin',
	},
	{
		display: '(UTC+09:30) Adelaide',
		id: 'Australia/Adelaide',
	},
	{
		display: '(UTC+10:00) Hobart',
		id: 'Australia/Hobart',
	},
	{
		display: '(UTC+10:00) Yakutsk',
		id: 'Asia/Yakutsk',
	},
	{
		display: '(UTC+10:00) Brisbane',
		id: 'Australia/Brisbane',
	},
	{
		display: '(UTC+10:00) Guam, Port Moresby',
		id: 'Pacific/Port_Moresby',
	},
	{
		display: '(UTC+10:00) Canberra, Melbourne, Sydney',
		id: 'Australia/Sydney',
	},
	{
		display: '(UTC+11:00) Vladivostok',
		id: 'Asia/Vladivostok',
	},
	{
		display: '(UTC+11:00) Solomon Islands, New Caledonia',
		id: 'Pacific/Guadalcanal',
	},
	{
		display: '(UTC+12:00) Coordinated Universal Time+12',
		id: 'Etc/GMT-12',
	},
	{
		display: '(UTC+12:00) Fiji, Marshall Islands',
		id: 'Pacific/Fiji',
	},
	{
		display: '(UTC+12:00) Magadan',
		id: 'Asia/Magadan',
	},
	{
		display: '(UTC+12:00) Auckland, Wellington',
		id: 'Pacific/Auckland',
	},
	{
		display: "(UTC+13:00) Nuku'alofa",
		id: 'Pacific/Tongatapu',
	},
	{
		display: '(UTC+13:00) Samoa',
		id: 'Pacific/Apia',
	},
]
