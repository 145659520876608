const API_URL = process.env.REACT_APP_API_URL
const V1_API_URL = `${API_URL}`
const V1_NOTI_API_URL = `${process.env.REACT_APP_NOTI_API_URL}`
const V1_IDENTITY_API_URL = `${process.env.REACT_APP_IDENTITY_API_URL}/api/v1`
const V1_IDENTITY_URL = `${process.env.REACT_APP_IDENTITY}`
const PUBLIC_API_URL = `${API_URL}/api/`
const PUBLIC_URL = process.env.PUBLIC_URL
const LANDING_URL = process.env.REACT_APP_LANDING_URL
const V3_API_OPS = `${process.env.REACT_APP_CLOUD_OPERATION_V3}/api/v1`

const SSO_CONFIGURES = {
	url: process.env.REACT_SSO_CONFIGURES_URL,
	redirectUri: process.env.REACT_SSO_CONFIGURES_REDIRECT_URL,
	realm: 'akaclaud-Dev',
	clientId: 'akaclaud-labs',
	onLoad: 'login-required',
	'enable-cors': true,
	checkLoginIframeInterval: 900,
}

export {
	V1_API_URL,
	V1_NOTI_API_URL,
	PUBLIC_API_URL,
	SSO_CONFIGURES,
	PUBLIC_URL,
	LANDING_URL,
	V1_IDENTITY_API_URL,
	V1_IDENTITY_URL,
	V3_API_OPS,
}
