import axios from 'axios'

import { V1_IDENTITY_API_URL, LANDING_URL } from '../config'
import { getAccessToken } from '../helpers'
import { openNotification } from '@common/helpers'

const authHttp = axios.create({
	baseURL: V1_IDENTITY_API_URL,
})

authHttp.interceptors.request.use(
	(config) => {
		config.headers = { ...config.headers, ...getHeaders() }
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

authHttp.interceptors.response.use(
	(response) => {
		return response.data
	},
	(error) => {
		if (!error.response) {
			openNotification('error', 'Request time out!.')
		} else {
			switch (error.response.status) {
				case 200:
					// Do nothing
					break
				case 401:
					//window.location.href = `${LANDING_URL}/401`
					break
				case 403:
					break
				case 404:
					//window.location.href = `${LANDING_URL}/404`
					break
				case error.response.status >= 500:
					openNotification(
						'error',
						'Something went wrong! Please try again later!'
					)
					break
				default:
					if (error.response.data.messages?.length > 0) {
						openNotification('warning', error.response.data.messages[0].message)
					} else {
						openNotification('warning', error.response.data.message)
					}
					break
			}

			return Promise.reject(error)
		}
	}
)

function getHeaders() {
	const headers = {
		Authorization: 'Bearer ' + getAccessToken(),
		'x-dxc-appCode': 'CLOUD_OPS',
	}
	;['buid', 'costCenterId', 'companyId'].forEach((item) => {
		const value = localStorage.getItem(item)
		if (value) {
			headers[`x-dxc-${item.toLowerCase()}`] = value
		}
	})
	return headers
}

export default authHttp
