import logo from '@assets/images/logo1.svg'
import React from 'react'
import { Link } from 'react-router-dom'

const SidebarLogo = () => {
	return (
		<div className="gx-layout-sider-header akc-sider-header">
			<Link to="/" className="gx-site-logo">
				<img src={logo} height="27px" color="#147afc" alt="logo" />
			</Link>
		</div>
	)
}

export default SidebarLogo
