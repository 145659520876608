import {
	SET_CLOUD_ACCOUNTS,
	SELECT_CLOUD_ACCOUNT,
	SELECT_PROVIDER,
	SELECT_ALL_CLOUD_ACCOUNT,
	DESELECT_ALL_CLOUD_ACCOUNT,
} from '@constants/ActionTypes'
import {
	GET_CLOUD_ACCOUNT_FAILED,
	GET_CLOUD_ACCOUNT_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	accounts: [],
	selectedAccounts: [],
	provider: 'total',
	message: '',
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_CLOUD_ACCOUNT_SUCCESS:
			return {
				...state,
				accounts: action.payload,
			}
		case GET_CLOUD_ACCOUNT_FAILED:
			return {
				...state,
				message: action.payload,
			}
		case SET_CLOUD_ACCOUNTS: {
			return {
				...state,
				accounts: action.payload,
			}
		}
		case SELECT_CLOUD_ACCOUNT: {
			return {
				...state,
				selectedAccounts: state.selectedAccounts
					.filter((e) => e.csp !== action.payload.provider)
					.concat(action.payload.accounts),
			}
		}
		case SELECT_ALL_CLOUD_ACCOUNT: {
			return {
				...state,
				selectedAccounts: action.payload,
			}
		}
		case DESELECT_ALL_CLOUD_ACCOUNT: {
			return {
				...state,
				selectedAccounts: INIT_STATE.selectedAccounts,
			}
		}
		case SELECT_PROVIDER: {
			return {
				...state,
				provider: action.payload,
			}
		}
		default:
			return state
	}
}
