import { TIME_MODE, TIME_FORMAT } from '@constants'

export function getDateParams(timeType, date) {
	if (date) {
		if (timeType === TIME_MODE.DAILY) {
			return date.format(TIME_FORMAT.DATE)
		}
		if (timeType === TIME_MODE.MONTHLY) {
			return date.format(TIME_FORMAT.MONTH)
		}
		return date.format(TIME_FORMAT.YEAR)
	}
}

export function getRequestParams(params) {
	var requestParams = ''
	if (params) {
		Object.keys(params).forEach((key) => {
			if (params[key] !== null && params[key] !== '') {
				if (requestParams !== '') {
					requestParams += '&'
				}
				requestParams += key + '=' + params[key]
			}
		})
	}
	return requestParams
}
