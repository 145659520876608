import { from } from 'rxjs'
import { AuthHttp } from '@common/services'
import { V3_API_OPS } from '@common/config'

//GET /virtual-machines
export function getVirtualMachines(
	cloudaccountids,
	regions,
	status = '',
	page = 0,
	limit = 5
) {
	return from(
		AuthHttp.get(
			`${V3_API_OPS}/virtual-machines?cloudaccountids=${cloudaccountids?.join(
				','
			)}&regions=${regions?.join(
				','
			)}&vmstatus=${status}&page=${page}&limit=${limit}`
		)
	)
}
/*
export function getVMStatistic(
	virtualMachineUUID,
	period = 300,
	type = 'MINIMUM'
) {
	const metrics = `CPUUtilization,NetworkIn,NetworkOut,NetworkPacketsIn,NetworkPacketsOut,DiskReadOps,DiskWriteOps,DiskReadBytes,DiskWriteBytes`

	return from(
		AuthHttp.get(
			`${V3_API_OPS}/virtual-machines/test/${virtualMachineUUID}/statistic?metricNames=${metrics}&period=${period}&statistic=${type}&endTime=1695625128448&startTime=1695538800000`
		)
	)
}
*/
export function getVMStatistic(
	virtualMachineUUID,
	period = 300,
	type = 'MINIMUM',
	startTime,
	endTime,
	metric = 'CPUUtilization'
) {
	const metrics = `CPUUtilization,NetworkIn,NetworkOut,NetworkPacketsIn,NetworkPacketsOut,DiskReadOps,DiskWriteOps,DiskReadBytes,DiskWriteBytes`

	return from(
		AuthHttp.get(
			`${V3_API_OPS}/virtual-machines/test/${virtualMachineUUID}/statistic?metricNames=${metric}&period=${period}&statistic=${type}&endTime=${endTime}&startTime=${startTime}`
		)
	)
}
export function getVMDetail(virtualMachineUUID) {
	return from(
		AuthHttp.get(`${V3_API_OPS}/virtual-machines/${virtualMachineUUID}/detail`)
	)
}
//PUT /virtual-machines/{virtualMachineUUID}/start
//PUT /virtual-machines/{virtualMachineUUID}/stop
//PUT /virtual-machines/{virtualMachineUUID}/restart
export function updateVirtualMachineByIdAndAction(virtualMachineUUID, action) {
	return from(
		AuthHttp.put(
			`${V3_API_OPS}/virtual-machines/${virtualMachineUUID}/${action.toLowerCase()}`
		)
	)
}

//GET /virtual-machines/regions
export function getVirtualMachineStatus() {
	return from(AuthHttp.get(`${V3_API_OPS}/virtual-machines/vm-status`))
}

//GET /virtual-machines/regions
export function getVirtualMachineRegions() {
	return from(AuthHttp.get(`${V3_API_OPS}/virtual-machines/regions`))
}

// import axios from 'axios';

// import { from } from 'rxjs';
// import { V1_API_URL } from '../common/config';
// import { getAccessToken } from '../common/helpers';
// import { openNotification } from '@common/helpers';
// import { Modal } from 'antd';

// export function getVirtualMachineList(serviceGroupIds, cloudAccountIds) {
// 	if (cloudAccountIds) {
// 		let url = `/api/v1/virtual-machines?cloudAccountIds=${cloudAccountIds?.join(
// 			',')}`;
// 		let bodyData = [];

// 		for (let i = 0; i < serviceGroupIds.length; i++) {
// 			bodyData.push({'serviceGroupId': serviceGroupIds[i]});
// 		}

// 		const postBodyHttp = axios.create({
// 			baseURL: V1_API_URL
// 		});

// 		postBodyHttp.interceptors.request.use(
// 			(config) => {
// 				config.headers['Authorization'] = 'Bearer ' + getAccessToken();
// 				config.headers['Content-type'] = 'application/json';
// 				const businessUnit = localStorage.getItem('buid');
// 				const costCenterId = localStorage.getItem('costCenterId');
// 				const companyId = localStorage.getItem('companyId');
// 				if (businessUnit) {
// 					config.headers['x-dxc-buid'] = businessUnit;
// 				}
// 				if (costCenterId) {
// 					config.headers['x-dxc-costcenterid'] = costCenterId;
// 				}
// 				if (companyId) {
// 					config.headers['x-dxc-companyid'] = companyId;
// 				}
// 				return config;
// 			},
// 			(error) => {
// 				return Promise.reject(error);
// 			}
// 		);

// 		postBodyHttp.interceptors.response.use(
// 			(response) => {
// 				return response.data;
// 			},
// 			(error) => {
// 				if (error.response.status !== 200) {
// 					if (error.response.status >= 500) {
// 						openNotification(
// 							'error',
// 							'Something went wrong! Please try again later!'
// 						);
// 					} else if (
// 						error.response.status === 403 ||
// 						error.response.status === 401
// 					) {
// 						Modal.warning({
// 							title: 'Warning',
// 							content: 'You have no permission!',
// 						});
// 					} else {
// 						openNotification(
// 							'warning',
// 							error.response.data.message
// 								? error.response.data.message
// 								: error.response.data.messages[0].message
// 						);
// 					}
// 					return Promise.reject(error);
// 				}
// 				return Promise.reject(error);
// 			}
// 		);

// 		return from(postBodyHttp.post(url, bodyData));
// 	}
// }

// export function updateVirtualMachineStatus(
// 	resourceName,
// 	cloudAccountId,
// 	cspType,
// 	status
// ) {
// 	const body = {
// 		cloudAccountId: cloudAccountId,
// 		cspType: cspType,
// 		name: resourceName
// 	};

// 	if (status) {
// 		body['status'] = status;
// 	}

// 	const authenHttp = axios.create({
// 		baseURL: V1_API_URL
// 	});

// 	authenHttp.interceptors.request.use(
// 		(config) => {
// 			config.headers['Authorization'] = 'Bearer ' + getAccessToken();
// 			config.headers['Content-type'] = 'application/json';
// 			const businessUnit = localStorage.getItem('buid');
// 			const costCenterId = localStorage.getItem('costCenterId');
// 			const companyId = localStorage.getItem('companyId');
// 			if (businessUnit) {
// 				config.headers['x-dxc-buid'] = businessUnit;
// 			}
// 			if (costCenterId) {
// 				config.headers['x-dxc-costcenterid'] = costCenterId;
// 			}
// 			if (companyId) {
// 				config.headers['x-dxc-companyid'] = companyId;
// 			}
// 			return config;
// 		},
// 		(error) => {
// 			return Promise.reject(error);
// 		}
// 	);

// 	authenHttp.interceptors.response.use(
// 		(response) => {
// 			return response.data;
// 		},
// 		(error) => {
// 			if (error.response.status !== 200) {
// 				if (error.response.status >= 500) {
// 					openNotification(
// 						'error',
// 						'Something went wrong! Please try again later!'
// 					);
// 				} else if (
// 					error.response.status === 403 ||
// 					error.response.status === 401
// 				) {
// 					Modal.warning({
// 						title: 'Warning',
// 						content: 'You have no permission!',
// 					});
// 				} else {
// 					openNotification(
// 						'warning',
// 						error.response.data.message
// 							? error.response.data.message
// 							: error.response.data.messages[0].message
// 					);
// 				}
// 				return Promise.reject(error);
// 			}
// 			return Promise.reject(error);
// 		}
// 	);

// 	return from(authenHttp.put(`/api/v1/virtual-machines/${status}`, body));
// }

// export function searchVirtualMachine(
// 	page,
// 	limit,
// 	sortBy,
// 	sortOrder,
// 	virtualMachineName,
// 	regionNames,
// 	cspTypes
// ) {
// 	const authenHttp = axios.create({
// 		baseURL: V1_API_URL
// 	});

// 	authenHttp.interceptors.request.use(
// 		(config) => {
// 			config.headers['Authorization'] = 'Bearer ' + getAccessToken();
// 			config.headers['Content-type'] = 'application/json';
// 			const businessUnit = localStorage.getItem('buid');
// 			const costCenterId = localStorage.getItem('costCenterId');
// 			const companyId = localStorage.getItem('companyId');
// 			if (businessUnit) {
// 				config.headers['x-dxc-buid'] = businessUnit;
// 			}
// 			if (costCenterId) {
// 				config.headers['x-dxc-costcenterid'] = costCenterId;
// 			}
// 			if (companyId) {
// 				config.headers['x-dxc-companyid'] = companyId;
// 			}
// 			return config;
// 		},
// 		(error) => {
// 			return Promise.reject(error);
// 		}
// 	);

// 	authenHttp.interceptors.response.use(
// 		(response) => {
// 			return response.data;
// 		},
// 		(error) => {
// 			if (error.response.status !== 200) {
// 				if (error.response.status >= 500) {
// 					openNotification(
// 						'error',
// 						'Something went wrong! Please try again later!'
// 					);
// 				} else if (
// 					error.response.status === 403 ||
// 					error.response.status === 401
// 				) {
// 					Modal.warning({
// 						title: 'Warning',
// 						content: 'You have no permission!',
// 					});
// 				} else {
// 					openNotification(
// 						'warning',
// 						error.response.data.message
// 							? error.response.data.message
// 							: error.response.data.messages[0].message
// 					);
// 				}
// 				return Promise.reject(error);
// 			}
// 			return Promise.reject(error);
// 		}
// 	);

// 	return from(
// 		authenHttp.get(
// 			`/api/v1/resources?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}&virtualMachineName=${virtualMachineName}&regionNames=${regionNames}&cspTypes=${cspTypes}`
// 		)
// 	);
// }
