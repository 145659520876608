import { from } from 'rxjs'
import { AuthHttp } from '@common/services'
import { PUBLIC_API_URL } from '@common/config'

//POST /vm-schedules
export function getResourceGroupByUUID(UUID) {
	return from(
		AuthHttp.get(`${PUBLIC_API_URL}template/get-all-resourceGroup/${UUID}`)
	)
}

export function createCategory(categoryName, viewId) {
	const body = {
		categoryName: categoryName,
	}
	return from(
		AuthHttp.post(`/api/v1/service-group-views/${viewId}/categories`, body)
	)
}

export function updateCategory(categoryId, viewId) {
	return from(
		AuthHttp.patch(
			`/api/v1/service-group-views/${viewId}/categories/${categoryId}`
		)
	)
}

export function deleteCategory(viewId, categoryId) {
	return from(
		AuthHttp.delete(
			`/api/v1/service-group-views/${viewId}/categories/${categoryId}`
		)
	)
}

export function createServiceGroup(
	viewId,
	categoryId,
	serviceGroupName,
	description
) {
	const body = {
		viewId: viewId,
		categoryId: categoryId,
		serviceGroupName: serviceGroupName,
		description: description,
	}
	return from(AuthHttp.post(`/api/v1/service-groups`, body))
}

export function updateServiceGroup(
	viewId,
	categoryId,
	serviceGroupId,
	serviceGroupName
) {
	const body = {
		viewId: viewId,
		categoryId: categoryId,
		serviceGroupId: serviceGroupId,
	}
	if (serviceGroupName) {
		body['serviceGroupName'] = serviceGroupName
	}
	return from(AuthHttp.put(`/api/v1/service-groups/${serviceGroupId}`, body))
}

export function deleteServiceGroup(serviceGroupId) {
	return from(AuthHttp.delete(`/api/v1/service-groups/${serviceGroupId}`))
}

export function searchResource(
	page,
	limit,
	sortBy,
	sortOrder,
	cloudAccountIds,
	productIds,
	regionIds
) {
	return from(
		AuthHttp.get(
			`/api/v1/resources?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}&cloudAccountIds=${cloudAccountIds}&productIds=${productIds}&regionIds=${regionIds}`
		)
	)
}

export function getProductsFromDetail(cloudAccountIds) {
	return from(
		AuthHttp.get(
			`/api/v1/products?cloudAccountIds=${cloudAccountIds?.join(',')}`
		)
	)
}

export function getRegionsFromDetail(cloudAccountIds) {
	return from(
		AuthHttp.get(
			`/api/v1/regions?cloudAccountIds=${cloudAccountIds?.join(',')}`
		)
	)
}

export function getRegionsByCloudID(CloudUUID) {
	return from(
		AuthHttp.get(`${PUBLIC_API_URL}deployment/getListRgByAss/${CloudUUID}`)
	)
}

export function getTagsFromDetail(tagKey) {
	return from(AuthHttp.get(`/api/v1/tags?tagKey=${tagKey}`))
}

export function updateServiceGroupDetail(serviceGroupId, body) {
	return from(AuthHttp.patch(`/api/v1/service-groups/${serviceGroupId}`, body))
}

export function getKeypairsFromRegion(region, UUID) {
	return from(
		AuthHttp.post(`${PUBLIC_API_URL}template/get-all-keyPair`, {
			cloudUUID: UUID,

			region: region,
		})
	)
}

export function getLocationByResource(cloudUUID, region) {
	return from(
		AuthHttp.post(`${PUBLIC_API_URL}deployment/get-params-info-by-rg`, {
			cloudUUID: cloudUUID,
			region: region,
		})
	)
}

export function createResourceGroup(body) {
	return from(AuthHttp.post(`${PUBLIC_API_URL}deployment/createRG`, body))
}

export function checkIfResourceGroupValid(cloudUUID, resourceName) {
	return from(
		AuthHttp.post(`${PUBLIC_API_URL}deployment/get-listByRG`, {
			cloudUUID: cloudUUID,
			resourceName: resourceName,
		})
	)
}

export function getInstanceTypeAws(cloudUUID, region) {
	return from(
		AuthHttp.post(`${PUBLIC_API_URL}deployment/getInstanceTypeAws`, {
			cloudUUID: cloudUUID,
			region: region,
		})
	)
}

export function createKeyPairsAws(cloudUUID, region, keypairName, keyType) {
	return from(
		AuthHttp.post(`${PUBLIC_API_URL}deployment/createKeyPairsAws`, {
			cloudUUID: cloudUUID,
			region: region,
			keypairName: keypairName,
			keyType: keyType,
		})
	)
}

export function getAllVMSByRG(cloudUUID, location) {
	return from(
		AuthHttp.post(`${PUBLIC_API_URL}deployment/get-all-VMS-byRG`, {
			cloudUUID: cloudUUID,
			location: location,
		})
	)
}

export function checkDeploymentNameDuplicate(deploymentName) {
	return from(
		AuthHttp.get(
			`${PUBLIC_API_URL}deployment/checkDeploymentName/${deploymentName}`
		)
	)
}

export function checkResourceGroupNameDuplicate(cloudUUID, rgName) {
	return from(
		AuthHttp.get(
			`${PUBLIC_API_URL}deployment/check-resource-group?cloudUUID=${cloudUUID}&rgName=${rgName}`
		)
	)
}
