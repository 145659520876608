export const COMMON_LOAD_USER_INFO_ACTION_TYPE =
	'COMMON_LOAD_USER_INFO_ACTION_TYPE'
export const LOAD_USER_PROFILE_ACTION_TYPE = 'LOAD_USER_PROFILE_ACTION_TYPE'
export const LOAD_USER_PROFILE_DETAIL_ACTION_TYPE =
	'LOAD_USER_PROFILE_DETAIL_ACTION_TYPE'

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU'
export const WINDOW_WIDTH = 'WINDOW-WIDTH'
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE'

//Contact Module const
export const FETCH_START = 'fetch_start'
export const FETCH_SUCCESS = 'fetch_success'
export const FETCH_ERROR = 'fetch_error'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER'
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER'

//Auth const
export const HOOK_AUTHORIZATION_TYPE = 'HOOK_AUTHORIZATION_TYPE'

export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER'
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS'
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER'
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS'
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER'
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS'
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER'
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success'
export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS'
export const INIT_URL = 'INIT_URL'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'

//Sticky
export const GET_STICKY = 'GET_STICKY'
export const NOTES_UPDATE = 'NOTES_UPDATE'
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS'
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS'

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS'
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'

// Cloud accounts
export const GET_CLOUD_ACCOUNT_SUCCESS = 'GET_CLOUD_ACCOUNT_SUCCESS'
export const GET_CLOUD_ACCOUNT_FAILED = 'GET_CLOUD_ACCOUNT_FAILED'
export const SET_CLOUD_ACCOUNTS = 'SET_CLOUD_ACCOUNTS'
export const SELECT_CLOUD_ACCOUNT = 'SELECT_CLOUD_ACCOUNT'
export const SELECT_PROVIDER = 'SELECT_PROVIDER'
export const SELECT_ALL_CLOUD_ACCOUNT = 'SELECT_ALL_CLOUD_ACCOUNT'
export const DESELECT_ALL_CLOUD_ACCOUNT = 'DESELECT_ALL_CLOUD_ACCOUNT'
export const SELECT_USER_COMPANY = 'SELECT_USER_COMPANY'
export const SELECT_USER_COST_CENTER = 'SELECT_USER_COST_CENTER'
export const SELECT_USER_BUSINESS_UNIT = 'SELECT_USER_BUSINESS_UNIT'
export const SELECT_RI_CLOUD_ACCOUNT = 'SELECT_RI_CLOUD_ACCOUNT'
export const SELECT_COST_CENTER = 'SELECT_COST_CENTER'
export const SELECT_MULTI_COST_CENTER = 'SELECT_MULTI_COST_CENTER'
export const SELECT_BUSINESS_UNIT = 'SELECT_BUSINESS_UNIT'

// Cloud Account Saga
export const CloudAccountActionType = {
	FETCH_ACCOUNT: 'FETCH_ACCOUNT',
}

//Service Catalog
export const GETTING_SERVICE = 'GETTING_SERVICE'
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS'
export const GET_SERVICE_FAILED = 'GET_SERVICE_FAILED'
export const CREATTING_SERVICE = 'CREATTING_SERVICE'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_FAILED = 'CREATE_SERVICE_FAILED'
export const GETTING_DETAIL_SERVICE = 'GETTING_DETAIL_SERVICE'
export const GET_DETAIL_SERVICE_SUCCESS = 'GET_DETAIL_SERVICE_SUCCESS'
export const GET_DETAIL_SERVICE_FAILED = 'GET_DETAIL_SERVICE_FAILED'
export const SET_IS_CLOSE_MODAL_SUCCESS = 'SET_IS_CLOSE_MODAL_SUCCESS'
export const SEARCHING_SERVICE = 'SEARCHING_SERVICE'
export const SEARCH_SERVICE_SUCCESS = 'SEARCH_SERVICE_SUCCESS'
export const SEARCH_SERVICE_FAILED = 'SEARCH_SERVICE_FAILED'
export const UPDATING_SERVICE = 'UPDATING_SERVICE'
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS'
export const UPDATE_SERVICE_FAILED = 'UPDATE_SERVICE_FAILED'
export const RESET_STATUS = 'RESET_STATUS'
export const DELETING_SERVICE = 'DELETING_SERVICE'
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS'
export const DELETE_SERVICE_FAILED = 'DELETE_SERVICE_FAILED'

//Service Catalog Saga
export const ServiceCatalogActionType = {
	FETCH_SERVICE: 'FETCH_SETVICE',
	CREATE_SERVICE: 'CREATE_SERVICE',
	FETCH_DETAIL_SERVICE: 'FETCH_DETAIL_SERVICE',
	SEARCH_SERVICE: 'SEARCH_SERVICE',
	UPDATE_SERVICE: 'UPDATE_SERVICE',
	DELETE_SERVICE: 'DELETE_SERVICE',
}

// Deployment Service Saga
export const CREATTING_SERVICE_DEPLOYMENT = 'CREATTING_SERVICE_DEPLOYMENT'
export const CREATE_SERVICE_DEPLOYMENT_SUCCESS =
	'CREATE_SERVICE_DEPLOYMENT_SUCCESS'
export const CREATE_SERVICE_DEPLOYMENT_FAILED =
	'CREATE_SERVICE_DEPLOYMENT_FAILED'
export const ServiceDeploymentActionType = {
	DEPLOYMENT_SERVICE: 'DEPLOYMENT_SERVICE',
}

// Template URL service saga

export const GETTING_TEMPLATE_URL_SERVICE = 'GETTING_TEMPLATE_URL_SERVICE'
export const GET_TEMPLATE_SERVICE_SUCCESS = 'GET_TEMPLATE_SERVICE_SUCCESS'
export const GET_TEMPLATE_SERVICE_FAILED = 'GET_TEMPLATE_SERVICE_FAILED'
export const RESET_STATUS_DEPLOYMENT = 'RESET_STATUS_DEPLOYMENT'

export const TemplateURLServiceActionType = {
	FETCH_TEMPLATE_URL: 'FETCH_TEMPLATE_URL',
}

// List Deployment Saga
export const ServiceDeploymentListActionType = {
	FETCH_LIST_DEPLOYMENT: 'FETCH_LIST_DEPLOYMENT',
	SEARCH_LIST_DEPLOYMENT: 'SEARCH_LIST_DEPLOYMENT',
	FETCH_DETAIL_LIST_DEPLOYMENT: 'FETCH_DETAIL_LIST_DEPLOYMENT',
	CLEAN_DEPLOYMENT: 'CLEAN_DEPLOYMENT',
	REFRESH_DEPLOYMENT: 'REFRESH_DEPLOYMENT',
}
export const GETTING_LIST_DEPLOYMENT = 'GETTING_LIST_DEPLOYMENT'
export const GET_LIST_DEPLOYMENT_SUCCESS = 'GET_LIST_DEPLOYMENT_SUCCESS'
export const GET_LIST_DEPLOYMENT_FAIL = 'GET_LIST_DEPLOYMENT_FAIL'
export const SEARCHING_LIST_DEPLOYMENT = 'SEARCHING_LIST_DEPLOYMENT'
export const SEARCH_LIST_DEPLOYMENT_SUCCESS = 'SEARCH_LIST_DEPLOYMENT_SUCCESS'
export const SEARCH_LIST_DEPLOYMENT_FAILED = 'SEARCH_LIST_DEPLOYMENT_FAILED'
export const GETTING_DETAIL_LIST_DEPLOYMENT = 'GETTING_DETAIL_LIST_DEPLOYMENT'
export const GET_DETAIL_LIST_DEPLOYMENT_SUCCESS =
	'GET_DETAIL_LIST_DEPLOYMENT_SUCCSS'
export const GET_DETAIL_LIST_DEPLOYMENT_FAILED =
	'GET_DETAIL_LIST_DEPLOYMENT_FAILED'

export const CLEANNING_DEPLOYMENT = 'CLEANNING_DEPLOYMENT'
export const CLEAN_DEPLOYMENT_SUCCESS = 'CLEAN_DEPLOYMENT_SUCCESS'
export const CLEAN_DEPLOYMENT_FAILED = 'CLEAN_DEPLOYMENT_FAILED'
export const RESET_STATUS_CLEAN = 'RESET_STATUS_CLEAN'

export const REFRESHING_LIST_DEPLOYMENT = 'REFRESHING_LIST_DEPLOYMENT'
export const REFRESH_LIST_DEPLOYMENT_SUCCESS = 'REFRESH_LIST_DEPLOYMENT_SUCCESS'
export const REFRESH_LIST_DEPLOYMENT_FAILED = 'REFRESH_LIST_DEPLOYMENT_FAILED'
export const REFRESH_CLEAN_STATUS = 'REFRESH_CLEAN_STATUS'

//Shared Template Saga
export const SharedTemplateActionType = {
	FETCH_SHARED_TEMPLATE: 'FETCH_SHARED_TEMPLATE',
	SEARCH_SHARED_TEMPLATE: 'SEARCH_SHARED_TEMPLATE',
	CLONE_TEMPLATE: 'CLONE_TEMPLATE',
}
export const GETTING_SHARED_TEMPLATE = 'GETTING_SHARED_TEMPLATE'
export const GET_SHARED_TEMPLATE_SUCCESS = 'GET_SHARED_TEMPLATE_SUCCESS'
export const GET_SHARED_TEMPMLATE_FAILED = 'GET_SHARED_TEMPMLATE_FAILED'

export const SEARCHING_SHARED_TEMPLATE = 'SEARCHING_SHARED_TEMPLATE'
export const SEARCH_SHARED_TEMPLATE_SUCCESS = 'SEARCH_SHARED_TEMPLATE_SUCCESS'
export const SEARCH_SHARED_TEMPLATE_FAILED = 'SEARCH_SHARED_TEMPLATE_FAILED'

export const CLONNING_TEMPLATE = 'CLONNING_TEMPLATE'
export const CLONE_TEMPLATE_SUCCESS = 'CLONE_TEMPLATE_SUCCESS'
export const CLONE_TEMPLATE_FAILED = 'CLONE_TEMPLATE_FAILED'
