import axios from 'axios'
import { getAccessToken } from '../common/helpers/Storage'
import { openNotification } from '../common/helpers'
import { PUBLIC_API_URL } from '../common/config'

let axiosClient = undefined

export const axiosWrapper = {
	getAxiosClient() {
		if (axiosClient) {
			return axiosClient
		}

		axiosClient = axios.create({
			baseURL: PUBLIC_API_URL,
			headers: {
				'content-type': 'application/json',
				Authorization: getAccessToken(),
			},
			timeout: 60000,
		})

		axiosClient.interceptors.request.use(
			(config) => {
				config.headers['Authorization'] = getAccessToken()
				const businessUnit = localStorage.getItem('buid')
				const costCenterId = localStorage.getItem('costCenterId')
				const companyId = localStorage.getItem('companyId')

				if (businessUnit) {
					config.headers['x-dxc-buid'] = businessUnit
				}
				if (costCenterId) {
					config.headers['x-dxc-costcenterid'] = costCenterId
				}
				if (companyId) {
					config.headers['x-dxc-companyid'] = companyId
				}
				config.headers['x-dxc-appCode'] = 'CLOUD_OPS'
				return config
			},
			(error) => {
				return Promise.reject(error)
			}
		)

		axiosClient.interceptors.response.use(
			(response) => {
				if (response && response.data) {
					return response.data
				}
				return response
			},
			(error) => {
				if (error.response.status !== 200) {
					if (error.response.status >= 500) {
						openNotification(
							'error',
							'Something went wrong! Please try again later!'
						)
					} else if (
						error.response.status === 403 ||
						error.response.status === 401
					) {
						/*
						Modal.warning({
							title: 'Warning',
							content: 'You have no permission!',
						})
						*/
					} else {
						openNotification(
							'warning',
							error.response.data.message
								? error.response.data.message
								: error.response.data.detail
						)
					}
					return Promise.reject(error)
				}
				return Promise.reject(error)
			}
		)

		return axiosClient
	},
}
