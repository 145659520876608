import {
	AUTH_USER_EMAIL,
	AUTH_USER_NAME,
	AUTH_USER_PREFERRED_USERNAME,
	AUTH_USER_ROLES,
	SSO_ACCESS_TOKEN,
} from './constants'

export const authorValid = (expectRoles, roles) => {
	const expectRolesArr = expectRoles.split(',')
	const rolesArr = roles.split(',')
	const hasRole = rolesArr.some((r) => expectRolesArr.indexOf(r) >= 0)

	return hasRole || expectRolesArr.some((r) => r === '*')
}

export const isLoggedIn = () => {
	const token = localStorage.getItem(SSO_ACCESS_TOKEN)

	return token != null && token.length > 0
}

export const getLoginUserInfo = () => {
	return {
		roles: localStorage.getItem(AUTH_USER_ROLES),
		name: localStorage.getItem(AUTH_USER_NAME),
		email: localStorage.getItem(AUTH_USER_EMAIL),
		userName: localStorage.getItem(AUTH_USER_PREFERRED_USERNAME),
	}
}

export const getAccessToken = () => {
	return localStorage.getItem(SSO_ACCESS_TOKEN)
}

export const getRoles = () => {
	return localStorage.getItem(AUTH_USER_ROLES)
}

// export const saveLoginInfo = ({ accessToken, refreshToken, accessRoles })  => {
//   localStorage.setItem(SSO_ACCESS_TOKEN, accessToken);
//   localStorage.setItem(SSO_REFRESH_TOKEN, refreshToken);
//   localStorage.setItem(AUTH_USER_ROLES, accessRoles);
// };
