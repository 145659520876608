import {
	SSO_ACCESS_TOKEN,
	AUTH_USER_EMAIL,
	AUTH_USER_NAME,
	AUTH_USER_PREFERRED_USERNAME,
	AUTH_USER_ROLES,
} from '../constants/sso/type'

export function getAccessToken() {
	return localStorage.getItem(SSO_ACCESS_TOKEN)
}

export function authorValid(expectRoles, roles) {
	const expectRolesArr = expectRoles.split(',')
	const rolesArr = roles.split(',')
	const hasRole = rolesArr.some((r) => expectRolesArr.indexOf(r) >= 0)

	return hasRole || expectRolesArr.some((r) => r === '*')
}

export function isLogin() {
	const token = localStorage.getItem(SSO_ACCESS_TOKEN)

	return token != null && token.length > 0
}

export function getLoginUserInfo() {
	return {
		roles: localStorage.getItem(AUTH_USER_ROLES),
		name: localStorage.getItem(AUTH_USER_NAME),
		email: localStorage.getItem(AUTH_USER_EMAIL),
		userName: localStorage.getItem(AUTH_USER_PREFERRED_USERNAME),
	}
}

export function addItem(key, value) {
	localStorage.setItem(key, value)

	return key
}

export function removeItem(key) {
	localStorage.removeItem(key)

	return key
}

export function clear() {
	localStorage.clear()
}

export function addObject(objs) {
	let count = 0

	for (let p in objs) {
		if ({}.hasOwnProperty.call(objs, p)) {
			localStorage.setItem(p, objs[p])
			count += 1
		}
	}

	return count
}
