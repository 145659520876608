import React from 'react'
import { Avatar, Popover, Divider } from 'antd'
import { logOut } from '@common/auth'
import { connect } from 'react-redux'
import { V1_IDENTITY_URL } from '@common/config'

const UserMenu = ({ user }) => {
	const userName =
		user.name && user.name !== 'undefined' ? user.name : user.userName

	const UserList = [userName.charAt(0).toUpperCase()]

	const handleLinkProfile = () => {
		window.open(V1_IDENTITY_URL + '/user-profile')
	}

	const userMenuOptions = (
		<ul className="akac-user-popover">
			<li className="akac-header-dropdown-menu ">
				<span
					onClick={() => handleLinkProfile()}
					style={{ cursor: 'pointer', color: '#2862ba' }}
				>
					My Profile
				</span>
			</li>
			<Divider className="akac-divider" />
			<li onClick={logOut} className="akac-header-dropdown-menu ">
				Logout
			</li>
		</ul>
	)

	return (
		<div className="gx-flex-row gx-align-items-center gx-avatar-row akac-header-menu">
			<Popover
				placement="bottomRight"
				content={userMenuOptions}
				trigger="click"
			>
				<Avatar
					src={require('@assets/images/ios-contact.svg')}
					className="gx-size-30 gx-pointer gx-mr-3"
					alt=""
				>
					{UserList}
				</Avatar>
				<span className="gx-avatar-name akac-header-menu-avatar">
					Hi, {userName}
					<i className="icon icon-chevron-down gx-fs-sm gx-ml-2" />
				</span>
			</Popover>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
})

export default connect(mapStateToProps, {})(UserMenu)
