import {
	CLEANNING_DEPLOYMENT,
	CLEAN_DEPLOYMENT_FAILED,
	CLEAN_DEPLOYMENT_SUCCESS,
	CREATE_SERVICE_DEPLOYMENT_FAILED,
	CREATE_SERVICE_DEPLOYMENT_SUCCESS,
	CREATTING_SERVICE_DEPLOYMENT,
	GETTING_DETAIL_LIST_DEPLOYMENT,
	GETTING_LIST_DEPLOYMENT,
	GETTING_TEMPLATE_URL_SERVICE,
	GET_DETAIL_LIST_DEPLOYMENT_FAILED,
	GET_DETAIL_LIST_DEPLOYMENT_SUCCESS,
	GET_LIST_DEPLOYMENT_FAIL,
	GET_LIST_DEPLOYMENT_SUCCESS,
	GET_TEMPLATE_SERVICE_FAILED,
	GET_TEMPLATE_SERVICE_SUCCESS,
	RESET_STATUS_DEPLOYMENT,
	SEARCHING_LIST_DEPLOYMENT,
	SEARCH_LIST_DEPLOYMENT_FAILED,
	SEARCH_LIST_DEPLOYMENT_SUCCESS,
	RESET_STATUS_CLEAN,
	REFRESHING_LIST_DEPLOYMENT,
	REFRESH_LIST_DEPLOYMENT_SUCCESS,
	REFRESH_LIST_DEPLOYMENT_FAILED,
	REFRESH_CLEAN_STATUS,
} from '../../constants/ActionTypes'
import {
	SET_DATA_STEP_ONE,
	SET_DATA_STEP_TWO,
	SET_DATA_STEP_THREE,
} from '../../constants/Deployment'

export const SetDataStep1 = (data) => {
	return {
		type: SET_DATA_STEP_ONE,
		payload: data,
	}
}

export const SetDataStep2 = (data) => {
	return {
		type: SET_DATA_STEP_TWO,
		payload: data,
	}
}

export const SetDataStep3 = (data) => {
	return {
		type: SET_DATA_STEP_THREE,
		payload: data,
	}
}

export const CreattingServiceDeployment = (data) => {
	return {
		type: CREATTING_SERVICE_DEPLOYMENT,
		payload: data,
	}
}

export const CreateServiceDeploymentSuccess = (data) => {
	return {
		type: CREATE_SERVICE_DEPLOYMENT_SUCCESS,
		payload: data,
	}
}

export const CreateServiceDeploymentFailed = (data) => {
	return {
		type: CREATE_SERVICE_DEPLOYMENT_FAILED,
		payload: data,
	}
}

export const GettingTemplateURLService = (data) => {
	return {
		type: GETTING_TEMPLATE_URL_SERVICE,
		payload: data,
	}
}

export const GetTemplateURLServiceSuccess = (data) => {
	return {
		type: GET_TEMPLATE_SERVICE_SUCCESS,
		payload: data,
	}
}

export const GetTemplateURLServiceFailed = (data) => {
	return {
		type: GET_TEMPLATE_SERVICE_FAILED,
		payload: data,
	}
}

export const ResetStatusDeployment = () => {
	return {
		type: RESET_STATUS_DEPLOYMENT,
	}
}

// Get List Deployment
export const GettingListDeploymentService = () => {
	return {
		type: GETTING_LIST_DEPLOYMENT,
	}
}

export const GetListDeploymentServiceSuccess = (data) => {
	return {
		type: GET_LIST_DEPLOYMENT_SUCCESS,
		payload: data,
	}
}

export const GetListDeploymentServiceFailed = (data) => {
	return {
		type: GET_LIST_DEPLOYMENT_FAIL,
		payload: data,
	}
}

// search list deployment

export const SearchingDeploymentListService = () => {
	return {
		type: SEARCHING_LIST_DEPLOYMENT,
	}
}

export const SearchDeploymentListServiceSuccess = (data) => {
	return {
		type: SEARCH_LIST_DEPLOYMENT_SUCCESS,
		payload: data,
	}
}

export const SearchDeploymentListServiceFailed = (data) => {
	return {
		type: SEARCH_LIST_DEPLOYMENT_FAILED,
		payload: data,
	}
}

// refresh
export const RefreshingDeploymentListService = () => {
	return {
		type: REFRESHING_LIST_DEPLOYMENT,
	}
}

export const RefreshDeploymentListServiceSuccess = (data) => {
	return {
		type: REFRESH_LIST_DEPLOYMENT_SUCCESS,
		payload: data,
	}
}

export const RefreshDeploymentListServiceFailed = (data) => {
	return {
		type: REFRESH_LIST_DEPLOYMENT_FAILED,
		payload: data,
	}
}

export const ResetStatusRefresh = () => {
	return {
		type: REFRESH_CLEAN_STATUS,
	}
}

// get detail
export const GettingDetailDeploymentListService = () => {
	return {
		type: GETTING_DETAIL_LIST_DEPLOYMENT,
	}
}

export const GetDetailDeploymentListServiceSuccess = (data) => {
	return {
		type: GET_DETAIL_LIST_DEPLOYMENT_SUCCESS,
		payload: data,
	}
}

export const GetDetailDeploymentListServiceFailed = (data) => {
	return {
		type: GET_DETAIL_LIST_DEPLOYMENT_FAILED,
		payload: data,
	}
}

export const CleanningDeployment = () => {
	return {
		type: CLEANNING_DEPLOYMENT,
	}
}

export const CleanDeploymentSuccess = (data) => {
	return {
		type: CLEAN_DEPLOYMENT_SUCCESS,
		payload: data,
	}
}

export const CleanDeploymentFailed = (data) => {
	return {
		type: CLEAN_DEPLOYMENT_FAILED,
		payload: data,
	}
}

export const ResetStatusClean = () => {
	return {
		type: RESET_STATUS_CLEAN,
	}
}
