import { from } from 'rxjs'
import { AuthHttp } from '@common/services'
import { getRequestParams } from '@helpers'
import { V3_API_OPS } from '@common/config'

export function getUnreadOpsNotifications() {
	return from(AuthHttp.get(`${V3_API_OPS}/notifications/unread`))
}
export function getOpsNotifications(params) {
	return from(
		AuthHttp.get(`${V3_API_OPS}/notifications?${getRequestParams(params)}`)
	)
}

export function markAsReadNotification(id) {
	return from(
		AuthHttp.patch(`${V3_API_OPS}/notifications?notificationIds=${id}`)
	)
}
export function markAsReadBatchNotifications(ids) {
	return from(
		AuthHttp.patch(
			`${V3_API_OPS}/notifications?notificationIds=${ids.join(',')}`
		)
	)
}
