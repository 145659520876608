import {
	CLONE_TEMPLATE_FAILED,
	CLONE_TEMPLATE_SUCCESS,
	CLONNING_TEMPLATE,
	RESET_STATUS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	data: [],
	status: undefined,
	loading: false,
	isCloneSuccess: undefined,
	message: '',
}

export const cloneTemplateReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case CLONNING_TEMPLATE:
			return {
				...state,
				loading: true,
			}
		case CLONE_TEMPLATE_SUCCESS:
			return {
				...state,
				status: payload,
				loading: false,
				isCloneSuccess: true,
				message: '',
			}
		case CLONE_TEMPLATE_FAILED:
			return {
				...state,
				message: String(payload),
				loading: false,
				isCloneSuccess: false,
			}
		case RESET_STATUS:
			return {
				...state,
				status: '',
				isCloneSuccess: undefined,
			}
		default:
			return state
	}
}
