import {
	DELETE_SERVICE_FAILED,
	DELETE_SERVICE_SUCCESS,
	DELETING_SERVICE,
	RESET_STATUS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	status: 0,
	deletedCount: undefined,
	loading: false,
	message: '',
}
export const deleteServiceReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case DELETING_SERVICE:
			return {
				...state,
				loading: true,
				deletedCount: undefined,
			}
		case DELETE_SERVICE_SUCCESS:
			return {
				...state,
				loading: false,
				deletedCount: 1,
				message: payload,
			}
		case DELETE_SERVICE_FAILED:
			return {
				...state,
				loading: false,
				deletedCount: 0,
				message: payload,
			}
		case RESET_STATUS:
			return {
				...state,
				loading: false,
				deletedCount: undefined,
				message: '',
				status: 0,
			}
		default:
			return state
	}
}
