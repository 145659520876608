import { call, put, takeLatest } from 'redux-saga/effects'
import {
	useCloneTemplate,
	useGetAllSharedTemplate,
	useGetSharedTemplatebyFilter,
} from '../../../api/hooks/SharedTemplateHook'
import { SharedTemplateActionType } from '../../constants/ActionTypes'

import {
	CloneSharedTemplateSuccess,
	CloneShareTemplateFailed,
	ClonningSharedTemplate,
	GetSharedTemplateFailed,
	GetSharedTemplateSuccess,
	GettingSharedTemplate,
	SeachingSharedTemplate,
	SearchSharedTemplateFailed,
	SearchSharedTemplateSuccess,
} from '../actions/SharedTemplate'

function* fetchSharedTemplate({ data }) {
	try {
		yield put(GettingSharedTemplate())

		const result = yield call(useGetAllSharedTemplate, data)
		if (result.length === 0) {
			yield put(GetSharedTemplateFailed())
		} else yield put(GetSharedTemplateSuccess(result))
	} catch (error) {
		yield put(GetSharedTemplateFailed(error))
	}
}

function* searchSharedTemplate({ data }) {
	try {
		yield put(SeachingSharedTemplate())

		const result = yield call(useGetSharedTemplatebyFilter, data)
		if (result.docs) {
			yield put(SearchSharedTemplateSuccess({ data, result }))
		}
	} catch (error) {
		yield put(SearchSharedTemplateFailed(error))
	}
}

function* cloneTemplate({ dataSubmit }) {
	try {
		yield put(ClonningSharedTemplate())

		const result = yield call(useCloneTemplate, dataSubmit)
		if (
			result.templateDataProcessing.status >= 400 &&
			result.templateDataProcessing.status <= 499
		) {
			yield put(CloneShareTemplateFailed(result))
		} else if (
			result.templateDataProcessing.status >= 200 &&
			result.templateDataProcessing.status <= 399
		) {
			yield put(
				CloneSharedTemplateSuccess(result?.templateDataProcessing.status)
			)
		}
	} catch (error) {
		if (error.response.data.imgUrl) {
			var message = error.response.data.imgUrl.message
			yield put(CloneShareTemplateFailed(message))
		}
		yield put(CloneShareTemplateFailed(error.response.data.message))
	}
}

function* SharedTemplateSaga() {
	yield takeLatest(
		SharedTemplateActionType.FETCH_SHARED_TEMPLATE,
		fetchSharedTemplate
	)
	yield takeLatest(
		SharedTemplateActionType.SEARCH_SHARED_TEMPLATE,
		searchSharedTemplate
	)
	yield takeLatest(SharedTemplateActionType.CLONE_TEMPLATE, cloneTemplate)
}

export default SharedTemplateSaga
