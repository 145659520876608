import {
	CLONE_TEMPLATE_FAILED,
	CLONE_TEMPLATE_SUCCESS,
	CLONNING_TEMPLATE,
	GETTING_SHARED_TEMPLATE,
	GET_SHARED_TEMPLATE_SUCCESS,
	GET_SHARED_TEMPMLATE_FAILED,
	SEARCHING_SHARED_TEMPLATE,
	SEARCH_SHARED_TEMPLATE_FAILED,
	SEARCH_SHARED_TEMPLATE_SUCCESS,
} from '../../constants'

export const GettingSharedTemplate = () => {
	return {
		type: GETTING_SHARED_TEMPLATE,
	}
}

export const GetSharedTemplateSuccess = (data) => {
	return {
		type: GET_SHARED_TEMPLATE_SUCCESS,
		payload: data,
	}
}

export const GetSharedTemplateFailed = (data) => {
	return {
		type: GET_SHARED_TEMPMLATE_FAILED,
		payload: data,
	}
}

export const SeachingSharedTemplate = () => {
	return {
		type: SEARCHING_SHARED_TEMPLATE,
	}
}

export const SearchSharedTemplateSuccess = (data) => {
	return {
		type: SEARCH_SHARED_TEMPLATE_SUCCESS,
		payload: data,
	}
}

export const SearchSharedTemplateFailed = (data) => {
	return {
		type: SEARCH_SHARED_TEMPLATE_FAILED,
		payload: data,
	}
}

export const ClonningSharedTemplate = () => {
	return {
		type: CLONNING_TEMPLATE,
	}
}

export const CloneSharedTemplateSuccess = (data) => {
	return {
		type: CLONE_TEMPLATE_SUCCESS,
		payload: data,
	}
}

export const CloneShareTemplateFailed = (data) => {
	return {
		type: CLONE_TEMPLATE_FAILED,
		payload: data,
	}
}
