import {
	HOOK_AUTHORIZATION_TYPE,
	COMMON_LOAD_USER_INFO_ACTION_TYPE,
	LOAD_USER_PROFILE_ACTION_TYPE,
	LOAD_USER_PROFILE_DETAIL_ACTION_TYPE,
	SELECT_MULTI_COST_CENTER,
	SELECT_COST_CENTER,
	SELECT_BUSINESS_UNIT,
} from '@constants/ActionTypes'

import { getLoginUserInfo } from '../../auth'

export const dispatchAuthorizationAction = (status, code) => ({
	type: HOOK_AUTHORIZATION_TYPE,
	payload: { status, code },
})

export const loadCurrentUserInfo = () => ({
	type: COMMON_LOAD_USER_INFO_ACTION_TYPE,
	payload: getLoginUserInfo(),
})

export const loadUserProfile = (company, costCenter, businessUnits) => ({
	type: LOAD_USER_PROFILE_ACTION_TYPE,
	payload: { company, costCenter, businessUnits },
})

export const loadUserProfileDetail = (detail) => ({
	type: LOAD_USER_PROFILE_DETAIL_ACTION_TYPE,
	payload: { detail },
})

export const selectCostCenter = (costCenterId) => ({
	type: SELECT_COST_CENTER,
	payload: costCenterId,
})

export const selectMultiCostCenter = (costCenterIds) => ({
	type: SELECT_MULTI_COST_CENTER,
	payload: costCenterIds,
})

export const selectBU = (ids) => ({
	type: SELECT_BUSINESS_UNIT,
	payload: ids,
})
