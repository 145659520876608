import {
	GETTING_SERVICE,
	GET_SERVICE_FAILED,
	GET_SERVICE_SUCCESS,
	SEARCHING_SERVICE,
	SEARCH_SERVICE_FAILED,
	SEARCH_SERVICE_SUCCESS,
} from '../../constants/ActionTypes'
const INIT_STATE = {
	data: [],
	loading: false,
	status: '',
	message: '',
	isSearch: false,
	param: '',
}

export const ServiceReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GETTING_SERVICE:
			return {
				...state,
				loading: true,
			}
		case GET_SERVICE_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
				isSearch: false,
			}
		case GET_SERVICE_FAILED:
			return {
				...state,
				loading: false,
				message: String(payload),
				isSearch: false,
			}
		case SEARCHING_SERVICE:
			return {
				...state,
				loading: true,
			}
		case SEARCH_SERVICE_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload.result,
				isSearch: true,
				param: payload.data.param,
			}
		case SEARCH_SERVICE_FAILED:
			return {
				...state,
				loading: false,
				message: String(payload),
				isSearch: true,
			}
		default:
			return state
	}
}
