import {
	GETTING_DETAIL_SERVICE,
	GET_DETAIL_SERVICE_FAILED,
	GET_DETAIL_SERVICE_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	data: [],
	status: undefined,
	loading: false,
	message: '',
}
export const detailServiceReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GETTING_DETAIL_SERVICE:
			return {
				...state,
				data: [],
				loading: true,
			}
		case GET_DETAIL_SERVICE_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
			}
		case GET_DETAIL_SERVICE_FAILED:
			return {
				...state,
				loading: false,
				message: payload,
			}
		default:
			return state
	}
}
