import {
	GETTING_TEMPLATE_URL_SERVICE,
	GET_TEMPLATE_SERVICE_FAILED,
	GET_TEMPLATE_SERVICE_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	templateURLData: null,
	message: '',
}

export const templateURLReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GETTING_TEMPLATE_URL_SERVICE:
			return {
				...state,
				templateURLData: payload,
			}
		case GET_TEMPLATE_SERVICE_SUCCESS:
			return {
				...state,
				templateURLData: payload,
			}
		case GET_TEMPLATE_SERVICE_FAILED:
			return {
				...state,
				message: payload,
			}
		default:
			return state
	}
}
