import {
	CREATE_SERVICE_DEPLOYMENT_FAILED,
	CREATE_SERVICE_DEPLOYMENT_SUCCESS,
	CREATTING_SERVICE_DEPLOYMENT,
	RESET_STATUS_DEPLOYMENT,
} from '../../constants/ActionTypes'
import {
	SET_DATA_STEP_ONE,
	SET_DATA_STEP_THREE,
	SET_DATA_STEP_TWO,
} from '../../constants/Deployment'

const INIT_STATE = {
	dataDeployment: [],
	isDeploySuccess: undefined,
	message: '',
	loading: false,
}

export const deploymentReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case SET_DATA_STEP_ONE:
			return {
				...state,
				dataDeployment: payload,
			}
		case SET_DATA_STEP_TWO:
			return {
				...state,
				dataDeployment: { ...state.dataDeployment, ...payload },
			}
		case SET_DATA_STEP_THREE:
			return {
				...state,
				dataDeployment: { ...state.dataDeployment, ...payload },
			}
		case CREATTING_SERVICE_DEPLOYMENT:
			return {
				...state,
				loading: true,
			}

		case CREATE_SERVICE_DEPLOYMENT_SUCCESS:
			return {
				...state,
				dataDeployment: payload,
				isDeploySuccess: true,
				loading: false,
			}
		case CREATE_SERVICE_DEPLOYMENT_FAILED:
			return {
				...state,
				message: payload,
				isDeploySuccess: false,
				loading: false,
			}
		case RESET_STATUS_DEPLOYMENT:
			return {
				...state,
				isDeploySuccess: undefined,
			}
		default:
			return state
	}
}
