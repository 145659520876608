import { from } from 'rxjs'
import { AuthHttp } from '@common/services'
import { V3_API_OPS } from '@common/config'

//GET /vm-schedules
export function getSchedulers(limit, page, searchBy, sortBy, sortOrder) {
	return from(
		AuthHttp.get(
			`${V3_API_OPS}/vm-schedules?limit=${limit}&page=${page}&searchBy=${
				searchBy ? searchBy : ''
			}&sortBy=${sortBy ? sortBy : ''}&sortOrder=${sortOrder ? sortOrder : ''}`
		)
	)
}

//POST /vm-schedules
export function createScheduler(body) {
	return from(AuthHttp.post(`${V3_API_OPS}/vm-schedules`, body))
}

//GET /vm-schedules/available-zones
export function getZones() {
	return from(AuthHttp.get(`${V3_API_OPS}/vm-schedules/available-zones`))
}

//GET /vm-schedules/{id}
export function getSchedulerById(id) {
	return from(AuthHttp.get(`${V3_API_OPS}/vm-schedules/${id}`))
}

//PUT /vm-schedules/{id}
export function updateSchedulerById(id, body) {
	return from(AuthHttp.put(`${V3_API_OPS}/vm-schedules/${id}`, body))
}

//PATCH /vm-schedules/{id}?scheduleStatus=
export function updateSchedulerStatusById(id, scheduleStatus) {
	return from(
		AuthHttp.patch(
			`${V3_API_OPS}/vm-schedules/${id}?scheduleStatus=${scheduleStatus}`
		)
	)
}

//DELETE: /vm-schedules?scheduleIds={0},{n}
export function deleteSchedulersByIds(ids) {
	return from(
		AuthHttp.delete(`${V3_API_OPS}/vm-schedules?scheduleIds=${ids.join(',')}`)
	)
}
