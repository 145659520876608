import React, { useState, useEffect } from 'react'
import { Button, Layout, Menu, Badge, Col, Dropdown } from 'antd'
import UserMenu from '@components/UserMenu'
import { useSelector } from 'react-redux'
import { login } from '@common/auth'
import {
	SettingOutlined,
	BellFilled,
	WarningFilled,
	CheckCircleFilled,
	ExclamationCircleOutlined,
} from '@ant-design/icons'
import {
	getUnreadOpsNotifications,
	markAsReadNotification,
	markAsReadBatchNotifications,
} from '@api'
import { openNotification } from '@common/helpers'
import { useHistory } from 'react-router-dom'
import { formatDateAgo } from '@util'
import notiIcon from '@assets/images/icons/noti.svg'

const { Header } = Layout

const Topbar = (props) => {
	const translate = props.translate
	const user = useSelector(({ user }) => user)
	const history = useHistory()
	const isLoggedIn = (user) => user && user.roles

	const [refresh, setRefresh] = useState(false)
	const [listNotification, setListNotification] = useState()

	setTimeout(() => {
		setRefresh(!refresh)
	}, 10000)

	useEffect(() => {
		const subscription = getUnreadOpsNotifications().subscribe((data) => {
			if (data) {
				setListNotification(data)
				setVisible(false)
			}
			subscription.unsubscribe()
		})
	}, [refresh])

	const markAsReadAll = () => {
		return markAsReadBatchNotifications(
			listNotification?.data.map((item) => item.id)
		).subscribe(
			(response) => {
				openNotification('success', 'Mark as read all notifications success')
				setRefresh(!refresh)
			},
			(error) => {
				openNotification('error', 'Mark as read notification error')
			}
		)
	}

	//GLOBAL ALERT
	/*
	useEffect(() => {
		// Subscribe to the event stream
		const eventSource = new EventSourcePolyfill(
			`${V3_API_OPS}/notifications/report-status`,
			{
				headers: {
					Authorization: `Bearer ${getAccessToken()}`,
					'x-dxc-buid': localStorage.getItem('buid'),
					'x-dxc-costcenterid': localStorage.getItem('costCenterId'),
					'x-dxc-companyid': localStorage.getItem('companyId'),
				},
			}
		)
		eventSource.addEventListener('message', handleReceiveMessage)
		return () => {
			// Remove event listener and close the connection on unmount
			eventSource.removeEventListener('message', handleReceiveMessage)
			eventSource.close()
		}
	}, [])
*/
	// Get the message and store it in the state
	/*
	const handleReceiveMessage = (event: any) => {
		const eventData = JSON.parse(event.data)
		if (eventData) {
			setRefresh(!refresh)
		}
		*/
	/*
		confirm({
			icon: '',
			content: (
				<>
					<p style={{ textAlign: 'center' }}>
						{eventData.resultStatus === 'SUCCESS' ? (
							<CheckCircleOutlined style={{ fontSize: 40, color: '#18C655' }} />
						) : (
							<WarningOutlined style={{ fontSize: 40, color: '#C01319' }} />
						)}
					</p>
					<h2 style={{ textAlign: 'center' }}>
						<b>{'Scheduler State'}</b>
					</h2>
					<p
						style={{
							textAlign: 'left',
							fontFamily: 'monospace',
							backgroundColor: 'black',
							color: 'white',
							borderRadius: 5,
							padding: 5,
						}}
					>
						{eventData.message}
					</p>
				</>
			),
			footer: null,
			showCancel: false,
		})
	}
		*/

	/*
	const [newRequest, setNewRequest] = useState("");
	useEffect(() => {
	  const socket = new WebSocket('wss://some-website.com/socket');
	  socket.addEventListener('message', ({ data }) => {
		const parsed = JSON.parse(data);
		setNewRequest(parsed.vc_request);
	  });
	  return () => socket.close();
	}, []);
  */
	//const [markAsReadDetailDOM, setMarkAsReadDetailDOM] = useState()
	//const [markAsReadDetail, setMarkAsReadDetail] = useState()

	/*
	const [showModal, setShowModal] = useState(false)
	const toggleShowModal = () => {
		setShowModal(!showModal)
	}
*/
	const markAsReadById = (id) => {
		const subscription = markAsReadNotification(id).subscribe(
			(response) => {
				openNotification('success', 'Mark as read notification success')
				setRefresh(!refresh)
				subscription.unsubscribe()
			},
			(error) => {
				openNotification('error', 'Mark as read notification error')
				subscription.unsubscribe()
			}
		)
	}
	/*
	const showDetail = (val, id) => {
		let rt = []
		rt.push(
			<p key={name}>
				<b style={{ minWidth: '200px', display: 'inline-block' }}>
					{'BU_NAME'}:
				</b>{' '}
				{
					userProfile.businessUnits.find((item) => item?.id === val['BU_ID'])
						?.name
				}
			</p>
		)
		for (var name in val) {
			if (!'COMPANY_ID,COST_CENTER_ID,BU_ID'.includes(name)) {
				rt.push(
					<p key={name}>
						<b style={{ minWidth: '200px', display: 'inline-block' }}>
							{name}:
						</b>{' '}
						{val[name]}
					</p>
				)
			}
		}
		setMarkAsReadDetailDOM(rt)
		setMarkAsReadDetail(val)
		toggleShowModal()
		markAsRead(id)
	}
	*/

	const [visible, setVisible] = useState(false)
	const handleVisibleChange = (visible) => {
		setVisible(visible)
	}

	const markAsReadAndRedirect = (id) => {
		markAsReadById(id)
		history.push('/notification')
	}

	return (
		<div className="akac-header">
			<Header
				className="akac-header-container"
				style={{ justifyContent: 'space-between' }}
			>
				<div></div>
				<Menu mode="horizontal" selectedKeys={'ops'}>
					<Menu.Item key="dashboard">
						<a href={`${process.env.REACT_APP_PORTAL}/dashboard`}>Dashboard</a>
					</Menu.Item>
					<Menu.Item key="resource">
						<a href={`${process.env.REACT_APP_PORTAL}/resource`}>Resource</a>
					</Menu.Item>
					<Menu.Item key="cost">
						<a href={`${process.env.REACT_APP_EXPENSE}/dashboard`}>Expense</a>
					</Menu.Item>
					<Menu.Item key="security">
						<a
							href={`${process.env.REACT_APP_SECURITY_ASSURANCE}/compliance/standards`}
						>
							Safe
						</a>
					</Menu.Item>
					<Menu.Item key="ops">
						<a href="/service-catalog">Automation</a>
					</Menu.Item>
					<Menu.Item key="identity">
						<a
							href={`${process.env.REACT_APP_IDENTITY}/user-area/cloud-accounts/aws`}
						>
							Identity
						</a>
					</Menu.Item>
				</Menu>

				<div className="akac-header-right-panel" style={{ display: 'flex' }}>
					{listNotification && (
						<Dropdown
							visible={visible}
							onVisibleChange={handleVisibleChange}
							overlay={
								<Menu className="notification-dropdown">
									<Menu.Item key={'header'}>
										<Col
											xl={12}
											lg={24}
											md={24}
											sm={24}
											xs={24}
											className="header-left"
										>
											<b>{translate.t('COMMON.0041')}</b>
										</Col>
										<Col
											xl={12}
											lg={24}
											md={24}
											sm={24}
											xs={24}
											className="header-right"
										>
											<small onClick={markAsReadAll}>
												{translate.t('COMMON.0042')}&nbsp;
												<SettingOutlined />
											</small>
										</Col>
									</Menu.Item>
									<Menu.Divider />
									<Menu.ItemGroup style={{ height: 400, overflow: 'scroll' }}>
										{listNotification?.data
											?.sort(function (a, b) {
												return new Date(b.created) - new Date(a.created)
											})
											.map((e) => {
												const rt = []
												rt.push(
													<Menu.Item
														key={e.id}
														onClick={() => markAsReadAndRedirect(e.id)}
													>
														<span style={{ position: 'relative' }}>
															<span>
																{e.resultStatus === 'WARNING' && (
																	<WarningFilled
																		style={{
																			fontSize: '13px',
																			color: 'rgb(240, 184, 38)',
																		}}
																	/>
																)}
																{e.resultStatus === 'SUCCEEDED' && (
																	<CheckCircleFilled
																		style={{
																			fontSize: '13px',
																			color: '#43a047',
																		}}
																	/>
																)}
																{e.resultStatus === 'FAILED' && (
																	<ExclamationCircleOutlined
																		style={{
																			fontSize: '13px',
																			color: '#c11319',
																		}}
																	/>
																)}{' '}
																{e.title}
															</span>
															<b className="block">{e.message}</b>
															<small className="block datetime">
																{formatDateAgo(translate, e.created)}
															</small>
															{/**
														<Button
															type="text"
															onClick={() => markAsReadById(e.id)}
															style={{
																position: 'absolute',
																right: -15,
																top: -7,
															}}
														>
															<CloseOutlined
																style={{
																	fontSize: '13px',
																	color: '#c11319',
																}}
															/>
														</Button>
														 */}
														</span>
													</Menu.Item>
												)
												rt.push(<Menu.Divider />)
												return rt
											})}
										{listNotification?.data.length === 0 && (
											<div style={{ padding: 20, textAlign: 'center' }}>
												<p>
													<img
														style={{
															fontSize: 13,
														}}
														alt="noti icon"
														src={notiIcon}
													/>
												</p>
												<p>
													<b>No Notifications Yet</b>
												</p>
												<p>
													<small>You have no notifications right now</small>
													<br />
													<small>Come back later</small>
												</p>
											</div>
										)}
									</Menu.ItemGroup>
									<Menu.Item
										onClick={() => history.push('/notification')}
										className="notification-footer"
										key={'footer'}
									>
										<b>{translate.t('COMMON.0043')}</b>
									</Menu.Item>
								</Menu>
							}
							trigger={['click']}
							getPopupContainer={(trigger) => trigger.parentElement}
						>
							<Button className="akac-header-notification">
								<Badge size="small" count={listNotification?.data?.length || 0}>
									{listNotification?.data?.length > 0 ? (
										<BellFilled
											style={{
												fontSize: '18px',
												color: '#f0b45f',
												transformOrigin: '50% 4px',
												animation: 'ring 4s .7s ease-in-out infinite',
											}}
										/>
									) : (
										<BellFilled
											style={{ fontSize: '18px', color: '#f0b45f' }}
										/>
									)}
								</Badge>
							</Button>
						</Dropdown>
					)}
					{isLoggedIn(user) ? (
						<UserMenu user={user} />
					) : (
						<Button
							className="akac-header-login-btn"
							type="primary"
							onClick={login}
						>
							Login
						</Button>
					)}
				</div>
			</Header>
		</div>
	)
}
export default Topbar
