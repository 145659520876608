import { PUBLIC_URL } from '@common/config'
import { SIDEBAR_MENU } from '@constants/Menu'
import {
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_FIXED,
} from '@constants/ThemeSetting'
import { onNavStyleChange } from '@appRedux/actions/Setting'
import { Col, Menu, Row, Tooltip } from 'antd'
import {
	DoubleRightOutlined,
	DoubleLeftOutlined,
	MenuOutlined,
} from '@ant-design/icons'
import CustomScrollbars from '@util/CustomScrollbars'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SidebarLogo from './SidebarLogo'
import { Link, useLocation, useHistory } from 'react-router-dom'

const SubMenu = Menu.SubMenu

const SidebarContent = ({ translate }) => {
	const dispatch = useDispatch()
	let { pathname } = useSelector(({ common }) => common)
	let { navStyle } = useSelector(({ settings }) => settings)

	const location = useLocation()
	const history = useHistory()

	const getNavStyleSubMenuClass = (navStyle) => {
		if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
			return 'gx-no-header-submenu-popup'
		}
		return ''
	}

	const defaultOpenKeys = SIDEBAR_MENU.map((menu) => {
		return menu.id
	}).filter((k) => k != null)

	const selectedKeys = SIDEBAR_MENU.reduce(
		(accumulator, currentValue) => accumulator.concat(currentValue.children),
		[]
	)
		.filter((m) => {
			return pathname.includes(m.url)
		})
		.map((e) => e.url)

	return (
		<div className="akac-sidebar-menu">
			<div className="akac-sidebar-top-menu">
				<SidebarLogo />
				<div className="application-title gx-site-logo">
					<MenuOutlined
						style={{ paddingRight: '10px' }}
						onClick={() => {
							dispatch(
								onNavStyleChange(
									navStyle === NAV_STYLE_FIXED
										? NAV_STYLE_MINI_SIDEBAR
										: NAV_STYLE_FIXED
								)
							)
						}}
					/>
					<span className="application-name">
						{translate.t('SIDEBAR.MAIN_MENU')}
					</span>
				</div>
				<div className="gx-sidebar-content">
					{defaultOpenKeys && defaultOpenKeys.length > 0 && (
						<CustomScrollbars className="akac-layout-sider-scrollbar">
							{navStyle === NAV_STYLE_FIXED && (
								<Menu
									defaultOpenKeys={defaultOpenKeys}
									selectedKeys={selectedKeys}
									className="sidebar-menu"
									theme="dark"
									mode="inline"
								>
									{SIDEBAR_MENU.map((submenu) => (
										<SubMenu
											key={submenu.id}
											popupClassName={getNavStyleSubMenuClass(navStyle)}
											title={
												<div>
													{submenu.icon !== '' && (
														<span className="icon-submenu">{submenu.icon}</span>
													)}
													<span>{translate.t(submenu.text)}</span>
												</div>
											}
										>
											{submenu.children
												? submenu.children.map((e) => (
														<Menu.Item
															key={`${PUBLIC_URL}${e.url}`}
															icon={
																<span className="icon-submenu">{e.icon}</span>
															}
															className="wrap-menu"
														>
															<Link to={`${PUBLIC_URL}${e.url}`}>
																<span className="gx-pl-1">
																	{translate.t(e.text)}
																</span>
															</Link>
														</Menu.Item>
												  ))
												: null}
										</SubMenu>
									))}
								</Menu>
							)}
							{navStyle === NAV_STYLE_MINI_SIDEBAR && (
								<Menu
									defaultOpenKeys={defaultOpenKeys}
									selectedKeys={selectedKeys}
									className="sidebar-menu"
									theme="dark"
									mode="inline"
								>
									{SIDEBAR_MENU.map((submenu) => {
										let subMenus = []
										submenu.children.map((link) => {
											subMenus.push(
												<SubMenu
													key={link.id}
													title={
														<Tooltip
															title={translate.t(link.text)}
															placement="right"
														>
															<span
																className={
																	location.pathname ===
																	`${PUBLIC_URL}${link.url}`
																		? 'ant-menu-icon-selected'
																		: ''
																}
																onClick={() =>
																	history.push(`${PUBLIC_URL}${link.url}`)
																}
															>
																{link.icon}
															</span>
														</Tooltip>
													}
												/>
											)
											return link
										})
										subMenus.push(<Menu.Divider />)
										return subMenus
									})}
								</Menu>
							)}
						</CustomScrollbars>
					)}
				</div>
			</div>
			<Row className="footer-bottom" justify="center">
				<Col span={24} className="footer-content">
					{navStyle === NAV_STYLE_FIXED && (
						<>
							<span>&copy; CloudSuite, FPT Software</span>
							<DoubleLeftOutlined
								className="footer-icon-fold"
								onClick={() => {
									dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR))
								}}
							/>
						</>
					)}
					{navStyle === NAV_STYLE_MINI_SIDEBAR && (
						<DoubleRightOutlined
							className="footer-icon-unfold"
							onClick={() => {
								dispatch(onNavStyleChange(NAV_STYLE_FIXED))
							}}
						/>
					)}
				</Col>
			</Row>
		</div>
	)
}

SidebarContent.propTypes = {}
export default SidebarContent
