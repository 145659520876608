import { axiosWrapper } from '../axiosClient'

const url = '/template'
export const useGetAllSharedTemplate = async ({ page, pageSize }) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient
		.get(`${url}/search-templates-public?page=${page}&limit=${pageSize}`)
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

export const useGetSharedTemplatebyFilter = async ({
	param,
	page,
	pageSize,
}) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient
		.get(
			`${url}/search-templates-public?page=${page}&limit=${pageSize}&${param}`
		)
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

export const useCloneTemplate = async (data) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	var formdata = new FormData()
	formdata.append('name', data.name)
	formdata.append('description', data.description)
	formdata.append('iacType', data.iacType)
	formdata.append('excerpt', data.excerpt)
	formdata.append('isPublic', data.isPublic)
	formdata.append('templateUrl', data.templateUrl)
	formdata.append('businessUnits', data.businessUnit)
	if (data.estimatedCost) {
		formdata.append('estimatedCost', data.estimatedCost)
	}
	if (data.imgUrl) {
		formdata.append('imgUrl', data.imgUrl)
	}
	if (data.scriptUrl instanceof File) {
		formdata.append('scriptUrl', data.scriptUrl, data.scriptUrl.name)
	}
	if (data.templateLink) {
		formdata.append('templateLink', data.templateLink)
	}
	if (data.guidelineUrl) {
		formdata.append('guidelineUrl', data.guidelineUrl)
	}

	const res = await axiosClient
		.post(`${url}/clone-template`, formdata, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}
