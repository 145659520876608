import {
	CREATE_SERVICE_FAILED,
	CREATE_SERVICE_SUCCESS,
	CREATTING_SERVICE,
	RESET_STATUS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
	data: [],
	status: undefined,
	loading: false,
	isCreateSuccess: undefined,
	message: '',
}

export const createServiceReducer = (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case CREATTING_SERVICE:
			return {
				...state,
				loading: true,
			}
		case CREATE_SERVICE_SUCCESS:
			return {
				...state,
				status: payload,
				loading: false,
				isCreateSuccess: true,
				message: '',
			}
		case CREATE_SERVICE_FAILED:
			return {
				...state,
				message: String(payload),
				loading: false,
				isCreateSuccess: false,
			}
		case RESET_STATUS:
			return {
				...state,
				status: '',
				isCreateSuccess: undefined,
			}
		default:
			return state
	}
}
