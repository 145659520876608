import { axiosWrapper } from '../axiosClient'

const url = '/template'
export const useGetAllService = async ({ page, pageSize, businessUnitId }) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient
		.get(
			`${url}/search-templates?page=${page}&limit=${pageSize}&businessUnits=${businessUnitId}`
		)
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

export const useGetServicebyFilter = async ({
	param,
	page,
	pageSize,
	businessUnitId,
}) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient
		.get(
			`${url}/search-templates?page=${page}&limit=${pageSize}&${param}&businessUnits=${businessUnitId}`
		)
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

export const useCreateService = async (data) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	var formdata = new FormData()
	formdata.append('name', data.name)
	formdata.append('description', data.description)
	formdata.append('iacType', data.iacType)
	formdata.append('excerpt', data.excerpt)
	formdata.append('isPublic', data.isPublic)
	formdata.append('created_by', data.created_by)
	if (data.estimatedCost) {
		formdata.append('estimatedCost', data.estimatedCost)
	}
	if (data.templateUrl instanceof File) {
		formdata.append('templateUrl', data.templateUrl, data.templateUrl.name)
	}
	if (data.paramUrl instanceof File) {
		formdata.append('paramUrl', data.paramUrl, data.paramUrl.name)
	}
	if (data.schemaUrl instanceof File) {
		formdata.append('schemaUrl', data.schemaUrl, data.schemaUrl.name)
	}
	if (data.imgUrl instanceof File) {
		formdata.append('imgUrl', data.imgUrl, data.imgUrl.name)
	}
	if (data.scriptUrl instanceof File) {
		formdata.append('scriptUrl', data.scriptUrl, data.scriptUrl.name)
	}
	if (data.templateLink) {
		formdata.append('templateLink', data.templateLink)
	}
	if (data.guidelineUrl) {
		formdata.append('guidelineUrl', data.guidelineUrl)
	}
	if (data.businessUnitId) {
		formdata.append('businessUnits', data.businessUnitId)
	}

	const res = await axiosClient
		.post(`${url}/create-template`, formdata, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

export const useGetDetailServiceById = async (id) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = await axiosClient.get(`${url}/${id}`).catch(function (error) {
		if (error) {
			return Promise.reject(error)
		}
	})
	return res
}

export const useUpdateService = async (data) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	var formdata = new FormData()
	formdata.append('name', data.name)
	formdata.append('iacType', data.iacType)
	formdata.append('excerpt', data.excerpt)
	formdata.append('templateId', data.templateId)
	formdata.append('isPublic', data.isPublic)
	formdata.append('created_by', data.created_by)
	if (data.deleteScriptUrl) {
		formdata.append('deleteScriptUrl', data.deleteScriptUrl)
	}
	if (data.deleteImgUrl) {
		formdata.append('deleteImgUrl', data.deleteImgUrl)
	}
	if (data.description !== null && data.description !== undefined) {
		formdata.append('description', data.description)
	}
	if (data.estimatedCost) {
		formdata.append('estimatedCost', data.estimatedCost)
	}
	if (data.templateUrl instanceof File) {
		formdata.append('templateUrl', data.templateUrl, data.templateUrl.name)
	}
	if (data.imgUrl instanceof File) {
		formdata.append('imgUrl', data.imgUrl, data.imgUrl.name)
	}
	if (data.scriptUrl instanceof File) {
		formdata.append('scriptUrl', data.scriptUrl, data.scriptUrl.name)
	}
	if (data.templateLink) {
		formdata.append('templateLink', data.templateLink)
	}
	if (data.businessUnitId) {
		formdata.append('businessUnits', data.businessUnitId)
	}
	if (data.guidelineUrl) {
		formdata.append('guidelineUrl', data.guidelineUrl)
	}

	const res = await axiosClient
		.put(`${url}/update-template`, formdata, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.catch(function (error) {
			if (error) {
				return Promise.reject(error)
			}
		})
	return res
}

export const useDeleteServiceById = (id) => {
	const axiosClient = axiosWrapper.getAxiosClient()
	const res = axiosClient.delete(`${url}/${id}`).catch(function (error) {
		if (error) {
			return Promise.reject(error)
		}
	})
	return res
}
